import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { AnimatePresence } from 'framer-motion';

// eslint-disable-next-line camelcase
import { Menus_MenuItemTag } from 'src/apollo/onlineOrdering';
import { formatImageURL } from 'src/lib/js/utilities';

import Image from 'shared/components/common/Image';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import { MenuItemTag } from 'public/components/default_template/online_ordering/item_tags/MenuItemTags';


type Props = {
  onClose: () => void;
  isOpen: boolean;
  withTransitions?: boolean;
  name?: string | null;
  description?: string | null;
  image?: string;
  // eslint-disable-next-line camelcase
  itemTags?: Menus_MenuItemTag[];
};

type BodyProps = {
  name?: string | null;
  description?: string | null;
  image?: string;
  // eslint-disable-next-line camelcase
  itemTags?: Menus_MenuItemTag[];
}

const ModalBody = (props: BodyProps) => {
  const { restaurant: { meta: { primaryColor } } } = useRestaurant();
  const tagColor = primaryColor ?? DEFAULT_COLORS.primary ?? '#000000';
  const { name, description, image, itemTags } = props;

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <div className="content">
          <ModalCloseButton className="itemModalCloseButton" />
          {image ?
            <div className="imageWrapper">
              {image ? <Image src={formatImageURL(image)} className="itemModalImage" eagerLoad /> : null}
            </div>
            : null}
          <div className="header">
            <h2 className="modalTitle">{name || <Skeleton />}</h2>
          </div>
          <div className="paddedContent">
            <p className="description">{description}</p>
            {itemTags && itemTags.length > 0 &&
                <div className="itemTagsContainer" data-testid="menu-item-tags">
                  {itemTags.map(tag => <MenuItemTag key={tag.guid} subtle tagColor={tagColor}>{tag.name?.toUpperCase()}</MenuItemTag>)}
                </div>}
          </div>
        </div>
      </AnimatePresence>
    </>
  );
};

const ReadOnlyItemModal = (props: Props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay fadeIn={props.withTransitions} fadeOut />
      <ModalContent wrapperClassName="itemModalWrapper" contentClassName="itemModalContent" slideIn={props.withTransitions} slideOut>
        <div className="itemModal">
          <ModalBody
            name={props.name}
            description={props.description}
            itemTags={props.itemTags}
            image={props.image} />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ReadOnlyItemModal;
