import {
  AddToCartMutation,
  CartQuery,
  CompletedOrderQuery,
  MenuItemDetailsQuery,
  MenuItemFromCartQuery,
  Menus_PricingRules as PricingRules,
  RestaurantSchedulesQuery,
  SelectionInput,
  UnitOfMeasure
} from 'src/apollo/onlineOrdering';

export type AddCartData = AddToCartMutation['addItemToCartV2'] & { __typename: 'CartResponse' };
export type CartData = CartQuery['cartV2'] & { __typename: 'CartResponse' } | undefined | null;
export type Cart = NonNullable<CartData>['cart'];
export type CartOrder = Cart['order'];
type CartRestaurant = Cart['restaurant'];

export type PreComputedTips = NonNullable<Cart>['preComputedTips'];
export type DeliveryInfo = NonNullable<CartOrder>['deliveryInfo'];
export type Location = NonNullable<CartRestaurant>['location'];

export type ScheduleType = RestaurantSchedulesQuery['restaurants'][0] & { __typename: 'Restaurant'};

export type ModifierGroup = MenuItemDetailsQuery['menuItemDetails']['modifierGroups'][0];
export type IModifierGroup = Omit<ModifierGroup, '__typename' | 'modifiers'> & { modifiers: IModifier[] };
export type Modifier = ModifierGroup['modifiers'][0];
export type IModifier = Omit<Modifier, 'modifierGroups'> & { modifierGroups?: IModifierGroup[] };

export type SelectionModifierGroup = MenuItemFromCartQuery['selectionItemDetails']['modifierGroups'][0];
export type ISelectionModifierGroup = Omit<SelectionModifierGroup, '__typename' | 'modifiers'> & { modifiers: SelectionModifier[] };
export type SelectionModifier = Omit<SelectionModifierGroup['modifiers'][0], 'modifierGroups'> & { modifierGroups?: ISelectionModifierGroup[] };

export type CartSelections = NonNullable<NonNullable<(CartQuery['cartV2'] & { __typename: 'CartResponse' })['cart']['order']>['selections']>;
export type CartSelection = CartSelections[0];

// https://github.com/toasttab/toast-reservations-2012/blob/master/modules/toast-java-common/app/com/toasttab/models/PricingMode.java
export type PricingMode = 'ADJUSTS_PRICE' | 'REPLACES_PRICE' | 'INCLUDED' | 'FIXED_PRICE';

export type Action = 'merge' | 'replace';

export type ModifierGroupData = {
  [key: string]: {
    guid: string;
    defaultOptionsChargePrice: string;
    defaultOptionsSubstitutionPricing: string;
    pricingRules: Partial<PricingRules>;
  }
}

export type Selections = { [groupGuid: string]: { [itemGuid: string]: SelectedModifier } };

export type SelectedModifier = {
  itemGroupGuid?: string | null;
  itemGuid: string;
  modifierGroups: Selections;
  name?: string;
  quantity: number;
  isDefault?: boolean;
  price?: number | null;
}

export type WrappedModifier = {
  masterId?: string | null;
  groupGuid: string;
  modifier: Modifier;
  modifierGroups: Selections;
  isRoot?: boolean;
  action: Action;
  quantity?: number;
  isDefault?: boolean;
  price?: number | null;
  specialInstructions?: string | null;
  usesFractionalQuantity?: boolean | null;
  unitOfMeasure?: UnitOfMeasure | null;
  name?: string | null;
}

export type CompletedOrder = CompletedOrderQuery['completedOrder'] & { __typename: 'CompletedOrder' };
export type CompletedOrderSelections = CompletedOrder['selections'];
export type CompletedOrderSelection = NonNullable<CompletedOrderSelections>[0];
type CompletedOrderModifierSelections = CompletedOrderSelection['modifiers'];
export type CompletedOrderModifierSelection = NonNullable<CompletedOrderModifierSelections>[0];

export type PaymentIntent = {
  externalReferenceId: string;
  amount: number;
}

export type BillingDetails = {
  name?: string;
  phoneNumber?: string;
  email?: string;
}

// get pricing for the selections in a modifier group, with discounts from `defaultOptionsSubstitutionPricing`
const getSelectionsPrice = (selections: Selections, modifierGroupData: ModifierGroupData, modifierGroupCredits: { [key: string]: number }, selectedMods: { [key: string]: string }): number => {
  return Object.entries(selections)
    .map<[number | undefined, number]>(([groupGuid, item]) =>
    [modifierGroupCredits[groupGuid], Object.values(item).map((mod, index) => getModifierPrice(mod, groupGuid, modifierGroupData, modifierGroupCredits, selectedMods, index))
      .reduce((a, b) => a + b, 0)])
    .reduce((price, [credits, modPrice]) => {
      return price + (credits ? Math.max(modPrice - credits, 0) : modPrice);
    }, 0);
};

// get the price for a specific modifier and any submodifiers, taking `defaultOptionsChargePrice` into account
export const getModifierPrice =
  (mod: SelectedModifier, groupGuid: string, modifierGroupData: ModifierGroupData, modifierGroupCredits: { [key: string]: number }, selectedMods: { [key: string]: string }, index: number): number => {
    const baseQuantity = mod.quantity || 1;

    const pricingRules = modifierGroupData[groupGuid]?.pricingRules;
    const sizeModGuid = pricingRules?.sizeSpecificPricingGuid; // modifier to check for size sequence pricing
    const sizeSequencePrices = pricingRules?.sizeSequencePricingRules;
    const defaultSequencePrices = sizeSequencePrices?.[0]?.sequencePrices;
    const sequencePrices = !sizeModGuid || !sizeSequencePrices || sizeSequencePrices.length === 1
      ? defaultSequencePrices // sequence pricing
      : sizeSequencePrices.find(size => size.sizeGuid === selectedMods[sizeModGuid])?.sequencePrices || defaultSequencePrices; // size sequence pricing

    // if default options aren't charged, treat the quantity as one less than it is
    const quantity = mod.isDefault && modifierGroupData[groupGuid]?.defaultOptionsChargePrice === 'NO' ? baseQuantity - 1 : baseQuantity;
    const thisPrice = mod.price
      ? quantity * mod.price
      : sequencePrices?.length
        ? new Array(quantity).fill('')
          .reduce((sum, _, qIndex) => sum + sequencePrices[Math.min(index + qIndex, sequencePrices.length - 1)]?.price, 0)
        : 0;

    if(Object.keys(mod.modifierGroups).length === 0) {
      return thisPrice;
    }

    return thisPrice + getSelectionsPrice(mod.modifierGroups, modifierGroupData, modifierGroupCredits, selectedMods) * baseQuantity;
  };

// get the price for a top-level modifier
// Note: this can return a price less than the base price for an item--the caller must take care
// to ensure that the final presented price is floored to that base price
export const getWrappedModifierPrice = (mod: WrappedModifier, modifierGroupData: ModifierGroupData, modifierGroupCredits: {[key: string]: number}, selectedMods: { [key: string]: string }): number => {
  const thisPrice = mod.price || 0;
  const quantity = mod.quantity || 1;
  if(Object.keys(mod.modifierGroups).length === 0) {
    return thisPrice * quantity;
  }

  return (thisPrice + getSelectionsPrice(mod.modifierGroups, modifierGroupData, modifierGroupCredits, selectedMods)) * quantity;
};

const getSelectionsNames = (selections: Selections): string[] => {
  return Object.values(selections)
    .flatMap(items => Object.values(items))
    .flatMap(item => [item.name, ...getSelectionsNames(item.modifierGroups)])
    .filter(Boolean) as string[];
};

export const getSubselectionNames = (mod: WrappedModifier, groupGuid: string, itemGuid: string): string[] => {
  const subGroups = mod.modifierGroups[groupGuid]?.[itemGuid]?.modifierGroups || {};

  return Object.values(subGroups)
    .flatMap(items => Object.values(items))
    .flatMap(item => [item.name, ...getSelectionsNames(item.modifierGroups)])
    .filter(Boolean) as string[];
};

const selectedModifierToSelectionInput = (item: SelectedModifier, quantity: number, parentItemGroupGuid: string): SelectionInput => {
  return {
    itemGuid: item.itemGuid,
    itemGroupGuid: item.itemGroupGuid || parentItemGroupGuid,
    quantity,
    modifierGroups: Object.entries(item.modifierGroups)
      .map(([guid, itemMap]) => ({
        guid,
        modifiers: Object.values(itemMap).flatMap(item => {
          const input = selectedModifierToSelectionInput(item, quantity, parentItemGroupGuid);
          return new Array(item.quantity).fill(input);
        })
      }))
  };
};

export const modifierToSelectionInput = (item: WrappedModifier): SelectionInput => {
  const quantity = item.quantity && !item.usesFractionalQuantity ? item.quantity : 1;

  return {
    itemGuid: item.modifier.itemGuid,
    itemGroupGuid: item.groupGuid,
    itemMasterId: item.masterId,
    quantity: quantity,
    specialInstructions: item.specialInstructions,
    fractionalQuantity: item.usesFractionalQuantity && item.unitOfMeasure
      ? {
        unitOfMeasure: item.unitOfMeasure,
        quantity: item.quantity || 1
      }
      : null,
    modifierGroups: Object.entries(item.modifierGroups)
      .map(([guid, itemMap]) => ({
        guid,
        modifiers: Object.values(itemMap).flatMap(modifier => {
          const input = selectedModifierToSelectionInput(modifier, quantity, item.groupGuid);
          return new Array(modifier.quantity).fill(input);
        })
      }))
  };
};

export const collapseModifierGroups = (groups: IModifierGroup[]): Selections => {
  if(Object.keys(groups).length === 0) {
    return {};
  }

  return groups.reduce((groupMap, group) => ({
    ...groupMap,
    [group.guid]: group.modifiers.filter(mod => mod.isDefault).reduce((itemMap, item) => ({
      ...itemMap,
      [item.itemGuid]: {
        itemGuid: item.itemGuid,
        itemGroupGuid: item.itemGroupGuid,
        name: item.name,
        isDefault: item.isDefault || false,
        quantity: 1,
        price: item.price,
        modifierGroups: item.modifierGroups ? collapseModifierGroups(item.modifierGroups) : []
      } as SelectedModifier
    }), {})
  }), {} as Selections);
};

const collapseSelectionModifierGroups = (groups: ISelectionModifierGroup[]): Selections => {
  if(Object.keys(groups).length === 0) {
    return {};
  }

  return groups.reduce((groupMap, group) => ({
    ...groupMap,
    [group.guid]: group.modifiers.filter(mod => mod.selected).reduce((itemMap, item) => ({
      ...itemMap,
      [item.itemGuid]: {
        itemGuid: item.itemGuid,
        itemGroupGuid: item.itemGroupGuid,
        name: item.name,
        isDefault: item.isDefault,
        quantity: item.quantity ? item.quantity : 1,
        price: item.price,
        modifierGroups: item.modifierGroups ? collapseSelectionModifierGroups(item.modifierGroups) : []
      }
    }), {})
  }), {});
};

export const collapseModifier = (modifier?: Omit<SelectionModifier, '__typename'>): WrappedModifier => {
  return {
    groupGuid: modifier?.itemGroupGuid || 'root',
    action: 'replace',
    modifier: modifier as Modifier,
    modifierGroups: modifier?.modifierGroups ? collapseSelectionModifierGroups(modifier.modifierGroups) : {},
    isRoot: true,
    price: modifier?.price || 0,
    quantity: 1
  };
};

export const calculateSubtotal = (order: CartOrder | CompletedOrder) => order?.preDiscountItemsSubtotal || 0;
