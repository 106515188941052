import React, { useMemo } from 'react';

import { createClient } from 'src/apollo/createClient';
import { Location } from 'src/apollo/onlineOrdering';
import { useToastLocalMapQuery } from 'src/apollo/sites';

import Image, { ImageProps } from 'shared/components/common/Image';

import { formatLocationAddress } from 'public/components/online_ordering/addressUtils';

import { resources } from 'config';


type Props = {
  id: string;
  rxName: string;
  location: Location;
  bannerImageUrl?: string;
};

const HeaderImage = ({ rxName, location, bannerImageUrl, children }: React.PropsWithChildren<Props>) => {
  const image: ImageProps | null = useMemo(() => {
    if(bannerImageUrl) {
      return {
        src: bannerImageUrl,
        alt: 'Restaurant banner image'
      };
    }

    return null;
  }, [bannerImageUrl]);

  const client = useMemo(() => createClient(resources.apiEndpoint), []);
  const formattedAddress = useMemo(() => formatLocationAddress(location), [location]);

  const { data } = useToastLocalMapQuery({
    variables: {
      width: 600,
      height: 150,
      scale: 2,
      zoom: 13,
      formattedAddress
    },
    client,
    skip: !!bannerImageUrl
  });

  const headerMapImg = useMemo(() => {
    if(data?.toastLocalMap) {
      return {
        alt: `A map showing the location of ${rxName}`,
        src: data.toastLocalMap
      };
    }
    return null;
  }, [rxName, data?.toastLocalMap]);

  return (
    <div className="rx-header-photo">
      <div className={image ? 'image-container' : 'map-container'}>
        {image?.src ?
          <Image {...image} /> :
          headerMapImg?.src ?
            <Image {...headerMapImg}></Image> :
            <p>This restaurant does not have any images</p>}
        {children}
      </div>
    </div>
  );
};

export default HeaderImage;
