import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { isValidPhoneNumber } from 'libphonenumber-js';
import partition from 'lodash/partition';

import { CompletedOrder, CustomerQuery } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';
import { useCart } from 'src/public/components/online_ordering/CartContext';
import { useCheckout } from 'src/public/components/online_ordering/CheckoutContext';
import { ORDER_KEY } from 'src/public/components/online_ordering/OrderContext';
import { useTimeBasedRules } from 'src/public/components/online_ordering/TimeBasedRuleContext';
import { useExperimentUserId } from 'src/shared/components/common/ab_testing/ABTestContext';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'src/shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { useMarketing } from 'public/components/online_ordering/MarketingContext';
import { useOffers } from 'public/components/online_ordering/OffersContext';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';
import { sumByField } from 'public/components/online_ordering/reducerUtils';

import { getFirstOrThirdParty } from './loyalty/LoyaltyUtils';


export type Customer = CustomerQuery['customer'] & { __typename: 'Customer' } | undefined | null;

export const getCustomerInfo = (customer: Customer) => {
  return {
    yourInfoPhone: customer?.phone || '',
    yourInfoEmail: customer?.email || '',
    yourInfoFirstName: customer?.firstName || '',
    yourInfoLastName: customer?.lastName || ''
  };
};

export const submitPhoneNumberImpl = async (
  phoneNumber: string,
  passwordlessLogin: (phoneNumber: string) => Promise<boolean>,
  setSentCode: React.Dispatch<React.SetStateAction<boolean>>,
  setPasswordlessError: React.Dispatch<React.SetStateAction<string>>
) => {
  if(phoneNumber && isValidPhoneNumber(phoneNumber, 'US')) {
    setSentCode(true);
    if(!await passwordlessLogin(phoneNumber)) {
      setPasswordlessError('Error sending confirmation code');
      setSentCode(false);
    } else {
      setPasswordlessError('');
    }
  }
};


// Enums to standardize data values sent to the tracker

export enum AuthenticationStatus {
  Authenticated = 'Authenticated',
  Guest = 'Guest'
}

export enum AuthenticationSource {
  AccountCreation = 'Create a Toast account',
  UserNav = 'User nav',
  ExpressCheckout = 'Express checkout with Toast',
  GuestCheckout = 'Guest checkout page'
}

export function getAuthenticationStatus(customer: Customer | undefined) {
  return customer ? AuthenticationStatus.Authenticated : AuthenticationStatus.Guest;
}

export function getPaymentOption(paymentType: string | null, paymentOption: PaymentOption | null, giftCardAppliedAmount: number, orderTotal: number) {
  if(giftCardAppliedAmount === orderTotal) {
    return 'GC';
  }
  if(paymentType) {
    switch(paymentOption) {
      case PaymentOption.UponReceipt:
        return 'CASH';
      case PaymentOption.PayNow:
        return paymentType;
      default:
        return 'null';
    }
  }
  switch(paymentOption) {
    case PaymentOption.UponReceipt:
      return 'CASH';
    case PaymentOption.CreditCard:
      return 'CC';
    case PaymentOption.ApplePay:
      return 'APPLEPAY';
    case PaymentOption.Paypal:
      return 'PAYPAL';
    case PaymentOption.Venmo:
      return 'VENMO';
    default:
      return 'null';
  }
}


export const useHandleCompletedOrderCallback = () => {
  const tracker = useTracker();
  const history = useHistory();
  const { cartGuid, cart, clearCart } = useCart();
  const { paymentOption, tipAmount, paymentType } = usePayment();
  const { giftCardAppliedAmount, orderTotal } = useCheckout();
  const { confirmationPath } = useRestaurantRoutes();
  const { ooRestaurant, restaurant: { pixelsV2 } } = useRestaurant();
  const { rankedPromoOfferDiscounts } = useOffers();
  const { selectionsInCartWithTBRs, getItemLeadTime } = useTimeBasedRules();
  const { createMarketingSubscriptionRequest, subscribeToSmsMarketing, smsAccountEnabled } = useMarketing();
  const userId = useExperimentUserId();

  return useCallback((completedOrder: CompletedOrder) => {
    const { customerV2 } = completedOrder;

    if(completedOrder?.guid) {
      if(cart?.order) {
        // It's safe to assume at this point that if the item has a lead time, its TBR is a lead time rule, and if it doesn't, its TBR is a preorder rule
        const [selectionsWithLeadTimeRules, selectionsWithPreorderRules] = partition(selectionsInCartWithTBRs, s => getItemLeadTime(s.itemGuid) !== undefined);
        tracker.trackConversion(cart, cartGuid!, pixelsV2 );
        tracker.track('Order placed', {
          restaurantGuid: cart!.restaurant!.guid,
          diningOption: cart.diningOptionBehavior,
          fulfillmentTime: cart.fulfillmentType,
          numItems: cart.order.numberOfSelections,
          subtotal: cart.order.preDiscountItemsSubtotal,
          tax: cart.order.taxV2,
          deliveryChargeTotal: cart.order.deliveryServiceCharges,
          gratuityServiceCharges: cart.order.gratuityServiceCharges,
          processingServiceCharges: cart.order.processingServiceCharges,
          nonDeliveryNonGratuityNonUbpServiceCharges: cart.order.nonDeliveryNonGratuityNonUbpServiceCharges,
          discounts: cart.order.discountsTotal,
          total: cart.order?.totalV2,
          paymentType: getPaymentOption(paymentType, paymentOption, giftCardAppliedAmount, orderTotal),
          giftCardApplied: giftCardAppliedAmount > 0,
          tipAmount,
          redemptionsAppliedToCheck: completedOrder.discounts.loyaltyDiscounts,
          firstOrThirdParty: getFirstOrThirdParty(ooRestaurant?.loyaltyConfig.programName),
          leadTimeRuleItemCount: sumByField('quantity')(selectionsWithLeadTimeRules),
          leadTimeRuleTotalPrice: sumByField('price')(selectionsWithLeadTimeRules),
          preorderRuleItemCount: sumByField('quantity')(selectionsWithPreorderRules),
          preorderRuleTotalPrice: sumByField('price')(selectionsWithPreorderRules),
          useExperimentUserId: userId,
          smsAccountEnabled: smsAccountEnabled,
          smsConsentProvided: subscribeToSmsMarketing,
          smsSubscriptionIncompleteData: smsAccountEnabled && subscribeToSmsMarketing && (!customerV2 || !customerV2?.phone),
          numOffersPresent: rankedPromoOfferDiscounts?.length ?? 0,
          numPromoCodesUsed: cart.order.discounts.restaurantDiscount?.promoCode ? 1 : 0,
          isTds: !!completedOrder.deliveryServiceInfo,
          orderGuid: completedOrder.guid
        });
      }

      // IF SMS CHECKBOX CHECKED, SUBSCRIBE TO SMS
      if(subscribeToSmsMarketing && customerV2 && customerV2?.phone) {
        createMarketingSubscriptionRequest({
          phoneNumber: customerV2.phone,
          firstName: customerV2.firstName || '',
          lastName: customerV2.lastName || ''
        });
      }

      clearCart();
      localStorage.setItem(ORDER_KEY, completedOrder.guid);
      history.push(confirmationPath, { completedOrder });
    }
  }, [
    cart,
    cartGuid,
    clearCart,
    confirmationPath,
    createMarketingSubscriptionRequest,
    history,
    tracker,
    paymentType,
    paymentOption,
    giftCardAppliedAmount,
    orderTotal,
    tipAmount,
    ooRestaurant?.loyaltyConfig.programName,
    getItemLeadTime,
    pixelsV2,
    selectionsInCartWithTBRs,
    smsAccountEnabled,
    subscribeToSmsMarketing,
    userId,
    rankedPromoOfferDiscounts
  ]);
};

export const useIsCartValid = () => {
  const { validateCart, refetchCart } = useCart();
  const { setOrderError } = useCheckout();

  const isCartValid = useCallback(async (errorRefetchesCartModifications?: boolean): Promise<boolean> => {
    const { validateCartPreCheckout } = await validateCart();
    const { __typename } = validateCartPreCheckout;

    if(__typename === 'CartResponse' && validateCartPreCheckout.info.length === 0 && validateCartPreCheckout.warnings.length === 0) {
      return true;
    }

    if(__typename === 'CartResponse') {
      if(validateCartPreCheckout.warnings.length !== 0) {
        setOrderError({
          type: validateCartPreCheckout.warnings[0].code,
          message: validateCartPreCheckout.warnings[0].message
        });
      } else if(validateCartPreCheckout.info.length !== 0) {
        setOrderError({
          type: validateCartPreCheckout.info[0].code,
          message: validateCartPreCheckout.info[0].message
        });
      }
    } else if(__typename === 'CartModificationError') {
      setOrderError({
        type: validateCartPreCheckout.code,
        message: validateCartPreCheckout.message,
        refetchCart: errorRefetchesCartModifications
      });
    } else if(__typename === 'CartOutOfStockError') {
      setOrderError({
        type: 'OUT_OF_STOCK',
        message: validateCartPreCheckout.message,
        refetchCart: errorRefetchesCartModifications
      });
    } else if(__typename === 'CartValidationError') {
      setOrderError({
        type: validateCartPreCheckout.key,
        message: validateCartPreCheckout.message
      });
    } else {
      setOrderError({
        type: 'unknown',
        message: 'Sorry, your order could not be sent.'
      });
    }

    // If configured, don't refetch the cart until after the error modal is closed for errors that modify the cart.
    // This helps prevent page re-renders that could prevent the error modal from displaying.
    if(!errorRefetchesCartModifications || !['CartModificationError', 'CartOutOfStockError'].includes(__typename)) {
      refetchCart();
    }

    return false;
  }, [refetchCart, setOrderError, validateCart]);

  return { isCartValid };
};
