import React from 'react';

import classnames from 'classnames';

import { LogoSizeOption, Image as ImageObject } from 'src/apollo/sites';
import { ScrollDirection } from 'src/lib/js/hooks/useScrollDirection';

import Image from 'shared/components/common/Image';
import Link from 'shared/components/common/link';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { resources } from 'config';

type Props = {
  logoLink?: string;
  logoLinkSameTab?: boolean;
  logoSrc: string;
  logoObject?: ImageObject | null;
  logoSize?: LogoSizeOption | null;
  scrollDirection?: ScrollDirection;
  style?: React.CSSProperties;
  onLoad?: (ev: React.SyntheticEvent) => void;
}

const NavLogo = (props: Props) => {
  const { restaurant, host } = useRestaurant();
  const isToastOrderPage = host === resources.toastOrderHost;
  const homepage = restaurant?.meta?.homepage;
  // If the homepage is configured to '/' but we're on the order subdomain, use the default value supplied by logoLink. For
  // OOPro, this is the default configuration.
  const logoLink = isToastOrderPage && (!homepage || homepage === '/') ? props.logoLink : homepage || props.logoLink || '/';
  const overflow = props.logoSize === LogoSizeOption.OverflowCircle && (!props.scrollDirection || props.scrollDirection === 'up');

  return (
    <div className="logoTitle" data-testid="logoTitle">
      <Link href={logoLink} sameTab={props.logoLinkSameTab}>
        {props.logoSrc ?
          <div
            className={classnames('logoWrapper', { 'overflow-circle': overflow })}>
            <h1 className="hiddenHeader" >{restaurant.name}</h1>
            <Image alt={`${restaurant.name}`} imageObject={props.logoObject} src={props.logoSrc} className="logo" editableComponentName="logo" eagerLoad onLoad={props.onLoad} />
            <div className={classnames('backgroundCircle', { hidden: !overflow })} style={props.style} />
          </div> :
          <h1 className={classnames({ smaller: restaurant?.name?.length > 40 })}>{restaurant?.name}</h1>}
      </Link>
    </div>
  );
};

export default NavLogo;
