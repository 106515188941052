import { Locale, checkLocale } from '@toasttab/buffet-pui-locale-utilities';
import { formatCurrency, Currency } from '@toasttab/buffet-pui-number-utilities';

import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

export type I18nType = {
  currency: Currency,
  locale: Locale
}

export function useFormatPrice(price: number) {
  const { ooRestaurant } = useRestaurant();
  const restaurantI18n: I18nType | undefined = ooRestaurant?.i18n as I18nType | undefined;

  return formatCurrency({ amount: price, currency: restaurantI18n?.currency || Currency.USD }, checkLocale(restaurantI18n?.locale));
}
