import React, { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ModalCloseButton, ModalContent, useModalContext } from 'src/shared/components/common/modal';

import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';

import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';


interface LogoutProps {}

export const Logout: FC<LogoutProps> = () => {
  const { pwlessLogout } = useCustomer();
  const { onClose } = useModalContext();
  const [loggingOut, setLoggingOut] = useState(true);

  const logout = useCallback(async () => {
    const success = await pwlessLogout();
    if(success) {
      onClose();
      toast.success('You are now logged out');
    } else {
      setLoggingOut(false);
    }
  },
  [onClose, pwlessLogout]);

  useEffect(() => {
    logout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalContent contentClassName="logoutModalContent">
      <div className="header" data-testid="Logout">
        <div className="headerText">{loggingOut ? 'Logging out...' : 'Log out failed'}</div>
        <div className="headerClose"><ModalCloseButton /></div>
      </div>
      <div className="body">
        {loggingOut && <LoadingSpinnerOverlay withBorderRadius />}
        {!loggingOut && <p>Something went wrong, please try again later</p>}
      </div>
    </ModalContent>
  );
};
