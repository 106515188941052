import React from 'react';

import { Image as ImageObject } from 'src/apollo/sites';
import { formatPrice } from 'src/lib/js/utilities';
import Image from 'src/shared/components/common/Image';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

type Props = {
  locationName: String;
  message?: string;
  recipientName?: string;
  amount?: number;
}

export const GiftCardIllustration = ({ locationName, amount, recipientName, message }: Props) => {
  const { restaurant } = useRestaurant();

  const bannerImg = restaurant?.config?.ooConfig?.heroImage || restaurant?.config?.ooConfig?.heroImg;

  return (
    <div className="giftCardIllustration">
      <div className="headlines">
        <HeartIcon />
        <p className="cta">Give a gift from</p>
        <p className="locationName">{locationName}</p>
      </div>
      <div className="card">
        <div className="tagIcon">
          <TagIcon />
        </div>
        <div className="bow">
          <Bow />
        </div>
        <div className="illustration">
          <div className="heading">
            <div className="rxDetails">
              <p>{locationName}</p>
            </div>
            {amount ?
              <p>
                {formatPrice(amount)}
              </p>
              : null}
          </div>
          <MaskedImage image={bannerImg || 'icons/presentCutlery.svg'} />
        </div>
        <div className="messages">
          <div className="primary">
            <span>A gift for</span>
            <span className="name">{recipientName || 'You'}</span>
          </div>
          <div className="secondary">{message || 'A treat from me to you!'}</div>
        </div>
      </div>
    </div>
  );
};

const MaskedImage = ({ image }: { image: ImageObject | string }) => {
  const img = typeof image === 'string' ? <Image className="maskedImage" src={image} /> : <Image className="maskedImage" imageObject={image} />;

  return (
    <div className="maskedImageWrapper">
      {img}
    </div>
  );
};

const HeartIcon = () =>
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line
      d="M2.69067 7.26889C1.94715 6.46391 1.53425 5.40832 1.53425 4.3125C1.52873 3.37822 1.86621 2.47982 2.47247 1.81492C3.07873 1.15002 3.90411 0.773079 4.76706 0.767003C5.63 0.773077 6.45538 1.15002 7.06165 1.81492C7.66791 2.47982 8.0054 3.37822 7.99988 4.3125L7.99948 4.29722C7.99576 3.83502 8.07664 3.37661 8.23744 2.94844C8.39824 2.52027 8.63578 2.13081 8.93635 1.80256C9.23691 1.47431 9.59454 1.21376 9.98861 1.03596C10.3827 0.858148 10.8054 0.766602 11.2323 0.766602C11.6592 0.766602 12.0819 0.858148 12.476 1.03596C12.87 1.21376 13.2277 1.47431 13.5282 1.80256C13.8288 2.13081 14.0663 2.52027 14.2271 2.94844C14.3879 3.37661 14.4688 3.83502 14.4651 4.29722C14.4651 5.40301 14.0492 6.46835 13.3 7.28166L7.99988 13.0354V13.017L2.69067 7.26889Z"
      fill="currentColor" stroke="currentColor" strokeWidth="1.16665" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;

const Bow = () =>
  <svg width="81" height="78" viewBox="0 0 81 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M78.5821 58.0643L2.50027 17.735" stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M51.1529 42.5209C54.9079 33.3515 67.2015 34.1533 68.5784 39.2604C69.9089 44.2141 60.1015 50.5271 51.1529 42.5209Z" stroke="currentColor" strokeWidth="4" />
    <path d="M49.1417 41.9582C39.5443 39.0022 39.5329 26.7872 44.3012 24.8433C49.2691 23.0063 56.4096 32.2287 49.1417 41.9582Z" stroke="currentColor" strokeWidth="4" />
    <path d="M50.5433 42.7073C52.1736 51.42 57.2705 59.4193 64.878 64.6508" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
    <path d="M25.7118 40.8425C33.255 44.7545 42.2496 45.7169 50.7911 42.9668" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
  </svg>;

const TagIcon = () =>
  <svg width="70" height="21" viewBox="0 0 70 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line
      d="M43 8.03516C43 3.61688 39.4183 0.0351562 35 0.0351562C30.5817 0.0351562 27 3.61688 27 8.03516H6C2.68629 8.03516 0 10.7214 0 14.0352C0 17.3489 2.68629 20.0352 6 20.0352H64C67.3137 20.0352 70 17.3489 70 14.0352C70 10.7214 67.3137 8.03516 64 8.03516H43Z"
      fill="currentColor" />
  </svg>;

