import { setCookie } from 'react-use-cookie';

import { getGuestUserInfoFromToken } from '@toasttab/authentication-utils';

import { PWLESS_ACCESS, PWLESS_REFRESH, REFRESH_COOKIE_EXPIRY_DAYS } from 'src/shared/components/common/authentication/constants';

// Returns true if the guest has completed their profile.
// Returns null if given a bad token.
export const isGuestProfileCreated = (accessToken?: string): boolean | null => {
  try {
    if(!accessToken) return null;
    // isProfileCreated can be undefined for guests who created profiles before the completeIdentityProfile endpoint existed.
    // these are assumed to have a profile.
    const guestInfo = getGuestUserInfoFromToken(accessToken);
    return guestInfo.isProfileCreated === true || guestInfo.isProfileCreated === undefined;
  } catch(e) {
    // getGuestUserInfoFromToken may throw an exception if given a bad token
    return null;
  }
};

const setAccessToken = (token: string) => setCookie(PWLESS_ACCESS, token);
const setRefreshToken = (token: string) => setCookie(PWLESS_REFRESH, token, { days: REFRESH_COOKIE_EXPIRY_DAYS });

export const setTokens = (accessToken: string, refreshToken: string, onSetTokens?: (accessToken: string) => void) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  if(onSetTokens) {
    onSetTokens(accessToken);
  }
};

