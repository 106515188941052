import React, { useCallback, useState } from 'react';

import classnames from 'classnames';

import { OrderHistoryQuery } from 'src/apollo/onlineOrdering';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

import Image from 'shared/components/common/Image';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { usePopoverContext } from 'shared/components/common/popover/PopoverContext';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { formatMDY } from 'shared/js/timeUtils';

import { CART_POPOVER_CONTEXT_KEY } from 'public/components/default_template/online_ordering/cart/CartModal';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useFulfillment } from 'public/components/online_ordering/FulfillmentContext';

const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  return formatMDY(date);
};

export type Order = OrderHistoryQuery['orderHistory']['orders'][0];

const OrderCard = ({ order, onReorder }: { order: Order; onReorder?: () => void; }) => {
  const [loading, setLoading] = useState(false);
  const { selectedLocation, restaurant } = useRestaurant();
  const context = usePopoverContext(CART_POPOVER_CONTEXT_KEY);
  const { reorder } = useCart();
  const { fulfillmentData } = useFulfillment();
  const dataTestId = 'order-card-' + order?.guid;

  const onClick = useCallback(async () => {
    if(!loading) {
      setLoading(true);
      await reorder(order.guid, selectedLocation.externalId, fulfillmentData?.cartFulfillmentData);
      context?.open();

      setLoading(false);

      if(onReorder) {
        onReorder();
      }
    }
  }, [loading, reorder, onReorder, setLoading, context, fulfillmentData, order, selectedLocation]);

  return (
    <button className={classnames('orderCard', { loading, squaredCorners: restaurant.config.ooConfig?.format?.squaredCorners })} key={order.guid} onClick={onClick} data-testid={dataTestId}>
      {loading ? <LoadingSpinnerOverlay withBorderRadius={!restaurant.config.ooConfig?.format?.squaredCorners} /> : null}
      <h4>
        {order.selections.map((selection, index) =>
          <div key={`${selection.name}${index}`} className="selection">
            {selection.name}
            <span className="quantity">x{selection.quantity}</span>
          </div>)}
        <div className="fade" />
      </h4>
      {order.estimatedFulfillmentDate ?
        <div className="subtitle">Ordered on {formatTimestamp(order.estimatedFulfillmentDate)}</div>
        : null}
      <div className="orderInfo">
        <div className="icon">
          <Image src="icons/shopping-cart.svg" />
          Reorder
        </div>
        <div className="price"><FormattedPrice value={order.totalV2} /></div>
      </div>
    </button>
  );
};

export default OrderCard;
