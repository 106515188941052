import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { RankedPromoOfferDiscount, RankedPromoOffersResponse, useRankedPromoOfferLazyQuery } from 'src/apollo/onlineOrdering';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useCart } from './CartContext';

export type OfferMap = {[guid: string]: string[]};

export type OffersContextType = {
  itemOffers: OfferMap,
  groupOffers: OfferMap,
  menuOffers: OfferMap,
  getOffersTextForEntity: (itemGuid?: string | null, groupGuid?: string | null, menuGuid?: string | null) => string[]
  rankedPromoOfferDiscounts?: RankedPromoOfferDiscount[]
  numOffersAchieved: number,
  loading: boolean
}

export const OffersContext = createContext<OffersContextType | undefined>(undefined);

const OffersContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { ooRestaurant } = useRestaurant();
  const { cartGuid, lastOrderSelectionChange } = useCart();
  const ooClient = useOOClient();
  const lastOrderSelectionChangeRef = useRef<Date | undefined>();
  const [loading, setLoading] = useState(true);

  const [getPromoOffers, { data: rankedOffers }] = useRankedPromoOfferLazyQuery({
    fetchPolicy: 'cache-and-network',
    client: ooClient
  });
  useEffect(() => {
    // refresh every time the cart changes
    if(ooRestaurant?.guid && lastOrderSelectionChange.getTime() != lastOrderSelectionChangeRef.current?.getTime()) {
      lastOrderSelectionChangeRef.current = lastOrderSelectionChange;
      getPromoOffers({ variables: { input: { restaurantGuid: ooRestaurant?.guid || '', requestSource: 'ONLINE_ORDERING', cartGuid: cartGuid } } });
    }
  }, [cartGuid, getPromoOffers, ooRestaurant?.guid, lastOrderSelectionChange]);

  const rankedPromoOffers = rankedOffers?.offers.rankedPromoOffers as RankedPromoOffersResponse;
  // a null discount field indicates that the offer pertains only to menu items/groups that are not visible in OO, so we filter them out here
  const rankedPromoOfferDiscounts = rankedPromoOffers?.rankedDiscounts?.filter(discount => discount.discount);

  useEffect(() => {
    setLoading(rankedPromoOfferDiscounts === undefined);
  }, [setLoading, rankedPromoOfferDiscounts]);

  const getOfferMapForEntity = (entityType: 'items' | 'groups' | 'menus', offers?: RankedPromoOfferDiscount[]) => {
    const offerMap: OfferMap = {};
    offers?.map(discount => {
      const { discount: offer, bannerGuid: offerGuid } = discount;
      const triggerEntityKey = entityType === 'items' ? 'triggerMenuItems' : entityType === 'groups' ? 'triggerMenuGroups' : 'triggerMenus';
      const entities = offer?.amountType === 'BOGO' ? offer?.bogoAction?.[entityType] : offer?.[triggerEntityKey];
      entities?.map(guid => offerMap[guid] = [...offerMap[guid] ?? [], offerGuid]);
    });
    return offerMap;
  };

  const itemOffers = useMemo(() => getOfferMapForEntity('items', rankedPromoOfferDiscounts), [rankedPromoOfferDiscounts]);
  const groupOffers = useMemo(() => getOfferMapForEntity('groups', rankedPromoOfferDiscounts), [rankedPromoOfferDiscounts]);
  const menuOffers = useMemo(() => getOfferMapForEntity('menus', rankedPromoOfferDiscounts), [rankedPromoOfferDiscounts]);

  const getOffersTextForEntity = useCallback((itemGuid?: string | null, groupGuid?: string | null, menuGuid?: string | null) => {
    const offerGuids = [
      ...itemGuid ? itemOffers[itemGuid] ?? [] : [],
      ...groupGuid ? groupOffers[groupGuid] ?? [] : [],
      ...menuGuid ? menuOffers[menuGuid] ?? [] : []
    ];
    return rankedPromoOfferDiscounts?.filter(offer => offerGuids.includes(offer.bannerGuid)).map(offer => offer.bannerText) ?? [];
  }, [itemOffers, groupOffers, menuOffers, rankedPromoOfferDiscounts]);

  const numOffersAchieved = useMemo(() => rankedPromoOfferDiscounts?.filter(offer => offer.percentComplete >= 100).length, [rankedPromoOfferDiscounts]);

  return (
    <OffersContext.Provider value={{
      itemOffers,
      groupOffers,
      menuOffers,
      getOffersTextForEntity,
      rankedPromoOfferDiscounts,
      numOffersAchieved,
      loading
    }}>
      {props.children}
    </OffersContext.Provider>);
};

export const useOffers = () => {
  const context = useContext(OffersContext);
  if(!context) {
    throw new Error('useOffers must be used within an OffersContextProvider');
  }

  return context;
};

export default OffersContextProvider;
