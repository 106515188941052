import React, { KeyboardEventHandler, KeyboardEvent, ReactNode, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { normalizeHtmlId } from 'src/shared/js/normalizeUtils';

import Image from 'shared/components/common/Image';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import { useCart } from 'public/components/online_ordering/CartContext';


const CheckoutSection = ({
  title,
  collapsible = false,
  children
} : {
  title?: string;
  collapsible?: boolean;
  children: ReactNode;
}) => {
  const { cart } = useCart();
  const [expanded, setExpanded] = useState(false);
  const id = React.useMemo(() => normalizeHtmlId(uuidv4()), []);

  const handleKeyDown = React.useCallback<KeyboardEventHandler<HTMLDivElement>>((e: KeyboardEvent) => {
    switch(e.key) {
      case ' ':
      case 'Enter':
        e.stopPropagation();
        e.preventDefault();
        setExpanded(!expanded);
        break;
    }
  }, [expanded]);

  if(!cart) {
    return (
      <section className="checkoutSection">
        <Skeleton width="100%" height="500px" />
      </section>
    );
  }

  if(collapsible && title) {
    return (
      <section className="checkoutSection">
        <div className="separator top" />
        <div
          id={`accordianheader${id}`}
          role="button"
          aria-controls={`panel${id}`}
          aria-expanded={expanded}
          className={classnames('checkoutSectionHeader collapsable')}
          onClick={() => setExpanded(!expanded)}
          onKeyDown={handleKeyDown}
          tabIndex={0}>
          {title}
          <Image alt={`${expanded ? 'Collapse' : 'Expand'} section`} className={classnames('controlIcon', expanded ? 'rotatedUp' : 'rotatedDown')} src="icons/chevron-right-gray.svg" />
        </div>
        <div id={`accordianpanel${id}`} aria-labelledby={`accordianheader${id}`} role="region">
          <AnimatedSection expanded={expanded}>
            {children}
          </AnimatedSection>
        </div>
        <div className="separator bottom" />
      </section>
    );
  }

  return (
    <section className="checkoutSection" >
      {title &&
        <div className={classnames('checkoutSectionHeader')} >
          {title}
        </div>}
      {children}
    </section>
  );
};

export default CheckoutSection;
