import { RequestContext } from 'src/lib/js/context';
import { getHost, getHostnameFromURL, getPath } from 'src/lib/js/utilities';

import config from 'config';

export const TOAST_LOCAL_PATH = '/local';
export const TOAST_LOCAL_ORDER_PATH = '/local/order';
export const TOASTTAB_BOO_PATH = '/online';
export const TOASTTAB_BOO_LOCATIONS_PATH = '/online/locations';

const isHost = (host: string, staticContext?: RequestContext) => {
  return (staticContext?.req?.headers['x-forwarded-host'] || getHost(staticContext)) === host;
};

// Request proxied from Toast Local (i.e. BOO workflow on a TL Rx Profile Page)
export const isToastLocalRequest = (staticContext?: RequestContext) => {
  const path = getPath(staticContext);
  return isHost(config.resources.toastLocalHost, staticContext) && path.startsWith(TOAST_LOCAL_PATH);
};

// Request from the toasttab ordering domain
export const isToastOrderLocationsRequest = (staticContext?: RequestContext) => {
  const path = getPath(staticContext);
  return isHost(config.resources.toastOrderHost, staticContext) && path.startsWith(TOASTTAB_BOO_LOCATIONS_PATH);
};

// Request from the toasttab ordering domain
export const isToastOrderRequest = (staticContext?: RequestContext) => {
  const path = getPath(staticContext);
  return isHost(config.resources.toastOrderHost, staticContext) && path.startsWith(TOASTTAB_BOO_PATH);
};

const getReferrerHostname = (staticContext?: RequestContext) => {
  // @ts-ignore
  const referrer = typeof window !== 'undefined' ? window.__REFERRER__ : staticContext?.req?.headers.referer;
  return getHostnameFromURL(referrer);
};

// Grab the short URL for a restaurant. For Rx Profile pages, this will be the third
// item in the URL path. For MapPage (i.e. /map), a query string determines which Rx should be loaded.
// MapPage is loaded within an iframe, so the referrer is checked.
export const getSiteShortUrl = (staticContext?: RequestContext, shortUrlQueryString?: string | null) => {
  if(isToastOrderLocationsRequest(staticContext)) {
    // e.g. https://www.toasttab.com/online/locations/534c0f5e-2052-48f9-9608-76b30ac51186
    return '';
  } else if(isToastOrderRequest(staticContext)) {
    // e.g. https://order.toasttab.com/online/max-fried-chicken
    return getPath(staticContext).split('/')[2] || '';
  } else if(isToastLocalRequest(staticContext)) {
    const pathParts = getPath(staticContext).split('/');
    if(pathParts.length >= 4 && pathParts[2] === 'order') {
      // e.g. https://www.toasttab.com/local/order/max-fried-chicken/r-c17250af-9a4e-427c-9cac-73d14a9fadf7
      return pathParts[3] || '';
    } else if(pathParts.length >= 3) {
      // e.g. https://www.toasttab.com/local/max-fried-chicken/r-c17250af-9a4e-427c-9cac-73d14a9fadf7
      return pathParts[2] || '';
    } else {
      return '';
    }
  } else {
    const referrerHostname = getReferrerHostname(staticContext);
    return (referrerHostname === config.resources.toastLocalHost || referrerHostname === config.resources.toastOrderHost) && shortUrlQueryString
      ? shortUrlQueryString
      : '';
  }
};

export const getSiteMgmtGroupGuid = (staticContext?: RequestContext) => {
  if(isToastOrderLocationsRequest(staticContext)) {
    // e.g. https://www.toasttab.com/online/locations/534c0f5e-2052-48f9-9608-76b30ac51186
    return getPath(staticContext).split('/')[3] || '';
  }

  return '';
};

export const getSiteRef = (staticContext?: RequestContext) => {
  if(isToastOrderLocationsRequest(staticContext)) {
    // e.g. https://www.toasttab.com/online/locations/534c0f5e-2052-48f9-9608-76b30ac51186/default
    return decodeURIComponent(getPath(staticContext).split('/')[4] || '');
  }

  return '';
};

export const getSiteDomain = (staticContext?: RequestContext) => {
  const referrerHostname = getReferrerHostname(staticContext);
  const host = getHost(staticContext);
  // If the host isn't the main sites domain, it's a custom site so we can just use that.
  // Otherwise, we're probably trying to load in an iframe so we need to validate the referer
  const siteDomain = host !== config.server.host ? host : referrerHostname || '';

  if(!siteDomain) {
    return undefined;
  }

  return siteDomain;
};
