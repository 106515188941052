import { TestSurface } from './types';

export const TESTS = {
  'tto-universal-links-v2': {
    variants: {
      control: 50,
      variant: 50
    },
    controlVariant: 'control',
    testSurfaces: [TestSurface.OOV4]
  }
};
