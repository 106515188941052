import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { RankedPromoOfferDiscount } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';
import { useCart } from 'src/public/components/online_ordering/CartContext';
import { useCheckout } from 'src/public/components/online_ordering/CheckoutContext';
import { useGiftCard } from 'src/public/components/online_ordering/GiftCardContext';
import { useOffers } from 'src/public/components/online_ordering/OffersContext';
import { PaymentOption, usePayment } from 'src/public/components/online_ordering/PaymentContext';
import { Modal, ModalContent, ModalOverlay, useModal } from 'src/shared/components/common/modal';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import config from 'config';

import DiscountPill, { DiscountPillState, DiscountPillType } from './DiscountPill';
import DiscountsModal, { DiscountsModalType } from './DiscountsModal';

const OffersSection = () => {
  const { rankedPromoOfferDiscounts } = useOffers();
  const { cart, applyPromoCode, removePromoCode, cartGuid, refetchCart } = useCart();
  const { isOpen, onClose, onOpen } = useModal();

  // Promo Codes
  const currentAppliedDiscount = useMemo(() => {
    return cart?.order?.discounts?.restaurantDiscount?.promoCode?.toUpperCase();
  }, [cart?.order?.discounts?.restaurantDiscount?.promoCode]);
  const applyOffer = useCallback(async (offer: string) => {
    const applyError = await applyPromoCode(offer);
    if(applyError) {
      toast.error('Error applying promo code');
    } else {
      toast.success('Offer successfully applied!');
      onClose();
    }
  }, [applyPromoCode, onClose]);

  const removeOffer = useCallback(async () => {
    const removeError = await removePromoCode();
    if(removeError) {
      toast.error('Error removing promo code');
    }
  }, [removePromoCode]);

  const onClickOffer = useCallback(async (offer: RankedPromoOfferDiscount) => {
    if(currentAppliedDiscount === offer.promoCode) {
      removeOffer();
    } else {
      applyOffer(offer.promoCode);
    }
  }, [applyOffer, currentAppliedDiscount, removeOffer]);

  // Gift Cards
  const { giftCardNumber, applyGiftCard, applyGiftCardExtension, giftCardError, removeGiftCard } = useGiftCard();
  const tracker = useTracker();
  const { paymentOption } = usePayment();
  const { ooRestaurant } = useRestaurant();
  const { giftCardAppliedAmount } = useCheckout();
  const gcLast4 = useMemo(() => giftCardNumber.slice(-4), [giftCardNumber]);
  const gcConfig = ooRestaurant?.giftCardConfig;
  const prevGiftCardAppliedAmount:React.MutableRefObject<number> = useRef(0);
  const firstOrThirdParty = gcConfig?.hasGiftCardsExtension ? '3P' : '1P';
  const acceptsGiftCards = useMemo(() =>
    paymentOption !== PaymentOption.Paypal &&
    paymentOption !== PaymentOption.Venmo &&
    (gcConfig?.redemptionAllowed || gcConfig?.hasGiftCardsExtension), [gcConfig?.hasGiftCardsExtension, gcConfig?.redemptionAllowed, paymentOption]);

  const applyGiftCardToCart = useCallback(async (giftCardNumber: string, code?: string) => {
    if(cartGuid && giftCardNumber && acceptsGiftCards) {
      if(gcConfig?.hasGiftCardsExtension) {
        tracker.track('Clicked apply gift card', {
          firstOrThirdParty,
          verificationCodeOptional: gcConfig?.supportVerificationCode,
          verificationCodeSubmitted: Boolean(code)
        });
        grecaptcha.enterprise.ready(async () => {
          const token = await grecaptcha.enterprise.execute(config.reCaptchaEnterprise.siteKey, { action: 'giftcard_extension_inquiry' });
          const tokenProto = `recaptcha:enterprise:${token}`;
          let pin = gcConfig?.supportVerificationCode ? code : undefined;
          await applyGiftCardExtension(cartGuid, giftCardNumber, tokenProto, pin);
        });
      } else {
        tracker.track('Clicked apply gift card', {
          firstOrThirdParty,
          verificationCodeOptional: false,
          verificationCodeSubmitted: false
        });
        applyGiftCard(cartGuid, giftCardNumber);
      }
    }
  }, [acceptsGiftCards, applyGiftCard, applyGiftCardExtension, cartGuid, firstOrThirdParty, gcConfig?.hasGiftCardsExtension, gcConfig?.supportVerificationCode, tracker]);

  const removeGiftCardFromCart = useCallback(() => {
    removeGiftCard();
  }, [removeGiftCard]);

  useEffect(() => {
    if(prevGiftCardAppliedAmount.current != giftCardAppliedAmount) {
      prevGiftCardAppliedAmount.current = giftCardAppliedAmount;
      refetchCart({ totalGiftCardBalance: giftCardAppliedAmount });
    }
  }, [refetchCart, giftCardAppliedAmount]);

  useEffect(() => {
    if(giftCardNumber) {
      toast.success('Gift card applied successfully');
      tracker.track('Gift card applied successfully', { firstOrThirdParty });
      setModalCurrentValue(undefined);
      onClose();
    } else if(giftCardError) {
      toast.error('Error applying gift card');
      tracker.track('Gift card error', { firstOrThirdParty, error: giftCardError });
    }
  }, [giftCardNumber, giftCardError, tracker, firstOrThirdParty, onClose]);
  const [gcWarningModalOpen, setGCWarningModalOpen] = useState(false);

  const promoModifierText = useMemo(() => `${currentAppliedDiscount ? 'Update' : 'Add'} Promo Code`, [currentAppliedDiscount]);
  const gcModifierText = useMemo(() => `${giftCardNumber.length !== 0 ? 'Update' : 'Add'} Gift Card`, [giftCardNumber]);
  const [modalType, setModalType] = useState<DiscountsModalType>(DiscountsModalType.OFFER);
  const [modalCurrentValue, setModalCurrentValue] = useState<string | undefined>(currentAppliedDiscount);
  const [modalHeader, setModalHeader] = useState<string>('');
  const [modalDescription, setModalDescription] = useState<string | undefined>(undefined);
  const onApply = useCallback((primary: string, secondary: string) => {
    if(modalType === DiscountsModalType.OFFER) {
      applyOffer(primary);
    } else {
      applyGiftCardToCart(primary, secondary);
    }
  }, [applyGiftCardToCart, applyOffer, modalType]);

  return (
    <div className="offersSectionContainer">
      <div className="header">
        Offers & Gifts (1 of each per order)
      </div>
      <div className="offerPills">
        {giftCardNumber.length !== 0 &&
        <DiscountPill
          pillKey="discount-gc-pill"
          text={`Gift Card ${gcLast4}`}
          state={DiscountPillState.ACTIVE}
          onClick={() => setGCWarningModalOpen(true)} />}
        {rankedPromoOfferDiscounts?.map(offer =>
          <DiscountPill
            key={`discount-offer-pill-${offer.bannerGuid}`}
            text={offer.bannerText}
            state={getDiscountPillState(offer, currentAppliedDiscount)}
            onClick={() => onClickOffer(offer)} />)}
      </div>
      <div className="buttonContainer">
        <DiscountPill
          text={`${promoModifierText} +`}
          type={DiscountPillType.BUTTON}
          onClick={() => {
            onOpen();
            setModalType(DiscountsModalType.OFFER);
            setModalHeader('Promo Code');
            setModalDescription('Any offers your cart qualifies for will appear at checkout. Use this section to apply any unlisted promo codes.');
            setModalCurrentValue(currentAppliedDiscount);
          }}
          testId="add-promo-modal-btn" />
        {acceptsGiftCards && <DiscountPill
          text={`${gcModifierText} +`}
          type={DiscountPillType.BUTTON}
          onClick={() => {
            onOpen();
            setModalHeader('Gift Card');
            setModalDescription(undefined);
            setModalType(DiscountsModalType.GIFT_CARD);
            setModalCurrentValue(giftCardNumber);
          }}
          testId="add-gc-modal-btn" />}
        <DiscountsModal
          header={modalHeader}
          description={modalDescription}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          onApply={onApply}
          currentValue={modalCurrentValue}
          type={modalType} />
        <RemoveGCWarningModal isOpen={gcWarningModalOpen} setIsOpen={setGCWarningModalOpen} onConfirm={removeGiftCardFromCart} />
      </div>
    </div>
  );
};

const getDiscountPillState = (offer: RankedPromoOfferDiscount, currentAppliedDiscount: string | undefined) => {
  if(offer.percentComplete < 100) {
    return DiscountPillState.LOCKED;
  }
  if(currentAppliedDiscount === offer.promoCode.toUpperCase()) {
    return DiscountPillState.ACTIVE;
  }
  return DiscountPillState.INACTIVE;
};

interface RemoveGCWarningModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onConfirm: () => void;
}
const RemoveGCWarningModal = ({ isOpen, setIsOpen, onConfirm }: RemoveGCWarningModalProps) => {
  const onClose = () => setIsOpen(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose} testId="remove-gc-modal">
      <ModalOverlay />
      <ModalContent>
        <div className="discountsModal">
          <div className="header">Remove gift card?</div>
          <div className="description">If you wish to re-add this gift card later, you will have to type it in again.</div>
          <div className="buttonContainer">
            <button className="modalButton cancel" type="button" onClick={onClose}>Cancel</button>
            <button className="modalButton confirm"
              type="button"
              onClick={() => {
                onConfirm();
                onClose();
              }}>Yes, remove
            </button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};


export default OffersSection;
