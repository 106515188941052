import React from 'react';


type Props = {
  onSelect: (e: React.SyntheticEvent) => void;
  className?: string;
  testId?: string

}

export const DropDownOption = (props: React.PropsWithChildren<Props>) => {
  return (
    <div
      className={props.className}
      tabIndex={0}
      onClick={ e => { props.onSelect(e); }}
      onKeyDown={ e => {
        if(e.code === 'Space' || e.code === 'Enter') {
          props.onSelect(e);
        }
      }}
      data-testid={props.testId ?? 'dropdown-option'}>
      {props.children}
    </div>
  );
};
