import React from 'react';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

import { calculateDiscountsTotal, useCartLineItems } from 'public/components/default_template/online_ordering/cart/cartUtils';
import { useSpi } from 'public/components/default_template/online_ordering/checkout/payment/useSpi';
import ItemPrice from 'public/components/default_template/online_ordering/order/ItemPrice';
import TaxesAndFees from 'public/components/default_template/online_ordering/order/TaxesAndFees';
import { CreditCardSurchargeDisclaimerWithItemPrice } from 'public/components/default_template/surcharges/CreditCardSurchargeDisclaimer';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { use3pdConfig } from 'public/components/online_ordering/FulfillmentContext';
import { usePayment } from 'public/components/online_ordering/PaymentContext';

type OrderPricesProps = {
  canShowSurcharges?: boolean;
};

/** Renders the line items impacting the order total */
const OrderPrices = ({ canShowSurcharges }: OrderPricesProps) => {
  const { uses3pd } = use3pdConfig();
  const { cart, loadingCart, subtotal, surcharges } = useCart();
  const { giftCardAppliedAmount, orderTotal } = useCheckout();
  const { tipAmount: tip, fundraisingAmount: fundraising } = usePayment();
  const { gratuityServiceCharges, processingServiceCharges, nonDeliveryNonGratuityNonUbpServiceCharges, deliveryChargeTotalAmount, tax } = useCartLineItems(cart as any)(tip, fundraising);
  const { spiSurchargingEnabled } = useSpi();
  const is3pdDelivery = uses3pd && cart?.diningOptionBehavior === DiningOptionBehavior.Delivery;
  const discountsTotal = calculateDiscountsTotal(cart?.order?.discounts?.globalReward?.amount || 0, cart?.order?.discountsTotal);

  return (
    <div className="orderPrices">
      {subtotal &&
          <>
            <ItemPrice loading={loadingCart} title="Subtotal" amount={subtotal} />
            {discountsTotal > 0 && <ItemPrice loading={loadingCart} title="Discounts" amount={discountsTotal} negative={true} />}
            {cart?.diningOptionBehavior === DiningOptionBehavior.Delivery && <ItemPrice loading={loadingCart} title="Delivery" amount={deliveryChargeTotalAmount || 0} />}
            <TaxesAndFees
              loading={loadingCart}
              tax={tax || 0}
              gratuityServiceCharges={gratuityServiceCharges || 0}
              processingServiceCharges={processingServiceCharges || 0}
              nonDeliveryNonGratuityNonUbpServiceCharges={nonDeliveryNonGratuityNonUbpServiceCharges || 0} />
          </>}
      {tip > 0 && <ItemPrice loading={loadingCart} title={is3pdDelivery ? 'Driver tip' : 'Tip'} amount={tip} />}
      {fundraising > 0 && <ItemPrice loading={loadingCart} title="Support & Give" amount={fundraising} />}
      {giftCardAppliedAmount > 0 && <ItemPrice loading={loadingCart} title="Gift Card" amount={giftCardAppliedAmount} negative={true} />}
      <div className="row total">
        <div>Total</div>
        <div><FormattedPrice value={orderTotal} /></div>
      </div>
      {canShowSurcharges && <CreditCardSurchargeDisclaimerWithItemPrice surcharges={surcharges} spiSurchargingEnabled={spiSurchargingEnabled} loadingCart={loadingCart} />}
    </div>
  );
};

export default OrderPrices;
