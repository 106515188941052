import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DiningOptionBehavior, FulfillmentType } from 'src/apollo/onlineOrdering';
import { ButtonType } from 'src/apollo/sites';
import { reportError } from 'src/lib/js/clientError';
import useTracker from 'src/lib/js/hooks/useTracker';
import { DropDownOption } from 'src/shared/components/common/dropdown/DropDownOption';

import Image from 'shared/components/common/Image';
import { useAlertModalContext } from 'shared/components/common/alert_modal/AlertModal';
import Button from 'shared/components/common/button';
import DropDown from 'shared/components/common/dropdown';
import ErrorNotice from 'shared/components/common/error_notice';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { useModalContext } from 'shared/components/common/modal';
import { REQUEST_FAILURE_MESSAGE } from 'shared/js/constants';
import { toDayString, toLocalTime } from 'shared/js/timeUtils';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import DiningBehaviorToggle from 'public/components/default_template/online_ordering/dining_behavior_toggle/DiningBehaviorToggle';
import { DeliveryAddressSelection } from 'public/components/default_template/online_ordering/dining_options/delivery_address_selection/DeliveryAddressSelection';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { getDateTimeIndexes, useFulfillment } from 'public/components/online_ordering/FulfillmentContext';
import { useTimeBasedRules } from 'public/components/online_ordering/TimeBasedRuleContext';

type Props = {
  forceSelectMessage?: string;
  behaviorToggleClassName?: string;
  onButtonClick?: () => void;
  removeItem?: boolean;
}

const DiningOptions = (props: Props) => {
  const { onButtonClick, removeItem } = props;
  const { cart, deleteFromCart } = useCart();
  const {
    fulfillmentData,
    canOrderTakeout,
    canOrderDelivery,
    canScheduleSelectedBehavior,
    selectedFulfillmentType,
    setSelectedFulfillmentType,
    selectedDiningOptionBehavior,
    selectedDiningOptionBehaviorSchedules,
    diningOptionBehaviorAvailability,
    updateCartFulfillment,
    fulfillmentError,
    setGuestSelectedFulfillmentTime
  } = useFulfillment();
  const { validDeliveryAddress } = useDelivery();
  const { onClose } = useModalContext();
  const { getFilteredFutureSchedules, itemInCartWithMaxLeadTime, itemsInCartWithPreorderRule, itemsInCartWithPickupWindowRule, getEarliestFulfillmentTime } = useTimeBasedRules();
  const futureSchedules = useMemo(() =>
    getFilteredFutureSchedules(
      getEarliestFulfillmentTime(itemInCartWithMaxLeadTime?.itemGuid, selectedDiningOptionBehavior),
      selectedDiningOptionBehaviorSchedules?.futureScheduleDates ?? [],
      selectedDiningOptionBehavior
    ),
  [selectedDiningOptionBehaviorSchedules, selectedDiningOptionBehavior, getFilteredFutureSchedules, getEarliestFulfillmentTime, itemInCartWithMaxLeadTime?.itemGuid]);
  const [selectedDayIndex, setSelectedDayIndex] = useState(-1);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(-1);
  const [submitting, setSubmitting] = useState(false);
  const tracker = useTracker();

  // can something in the modal be updated?
  const canUpdate = useMemo(() =>
    canScheduleSelectedBehavior || selectedDiningOptionBehavior === DiningOptionBehavior.Delivery || canOrderTakeout && canOrderDelivery,
  [canScheduleSelectedBehavior, selectedDiningOptionBehavior, canOrderTakeout, canOrderDelivery]);

  const scheduledOrdersOnly = useMemo(() => {
    const behavior = selectedDiningOptionBehavior === DiningOptionBehavior.TakeOut ? diningOptionBehaviorAvailability.takeout : diningOptionBehaviorAvailability.delivery;
    return behavior?.scheduledAvailable && !behavior.asapAvailable;
  },
  [diningOptionBehaviorAvailability, selectedDiningOptionBehavior]);

  const { openAlertModal } = useAlertModalContext();

  const itemToRemove = itemInCartWithMaxLeadTime ?? itemsInCartWithPreorderRule[itemsInCartWithPreorderRule.length - 1] ??
    itemsInCartWithPickupWindowRule[itemsInCartWithPickupWindowRule.length - 1];
  const asapAvailable = selectedDiningOptionBehaviorSchedules?.asapAvailableNow && !itemToRemove;

  useEffect(() => {
    if(!asapAvailable) {
      setSelectedFulfillmentType(FulfillmentType.Future);
    }
  }, [asapAvailable, setSelectedFulfillmentType]);

  const submit = useCallback(async () => {
    if(!selectedFulfillmentType || !selectedDiningOptionBehavior || selectedDiningOptionBehavior === DiningOptionBehavior.Delivery && !validDeliveryAddress && !cart?.order?.deliveryInfo) {
      return;
    }
    onButtonClick?.();
    if(!canUpdate) {
      onClose();
    } else {
      setSubmitting(true);

      let success = false;
      try {
        success = await updateCartFulfillment({
          fulfillmentType: selectedFulfillmentType,
          fulfillmentDateTime:
            selectedFulfillmentType === FulfillmentType.Future ?
              futureSchedules[Math.max(selectedDayIndex, 0)]?.times[Math.max(selectedTimeIndex, 0)]?.time :
              undefined,
          diningOptionBehavior: selectedDiningOptionBehavior,
          deliveryInfo: validDeliveryAddress
        });
      } catch(error) {
        openAlertModal(REQUEST_FAILURE_MESSAGE);
        reportError('Error changing cart fulfillment.', error);
      }

      setSubmitting(false);
      if(removeItem) {
        tracker.track('TEMP TBR - Updated fulfillment time', { timeBasedRules: itemInCartWithMaxLeadTime ? 'Minimum lead time' : 'Preorder' });
      }
      if(success) {
        onClose();
      }
    }
  }, [onButtonClick,
    canUpdate,
    onClose,
    updateCartFulfillment,
    selectedDayIndex,
    selectedTimeIndex,
    selectedFulfillmentType,
    selectedDiningOptionBehavior,
    futureSchedules,
    validDeliveryAddress,
    cart?.order?.deliveryInfo,
    itemInCartWithMaxLeadTime,
    openAlertModal,
    removeItem,
    tracker]);

  useEffect(() => {
    // Seeing the Dining Options is equivalent to the guest selecting their
    // fulfillment time, whether actively or passively, but only set that they've
    // selected it on unmount so the modal doesn't close as soon as it's opened.
    return () => setGuestSelectedFulfillmentTime(true);
  }, [setGuestSelectedFulfillmentTime]);

  useEffect(() => {
    const dateTimeIndexes =
    getDateTimeIndexes(fulfillmentData?.cartFulfillmentData?.fulfillmentDateTime || futureSchedules?.find(date => date?.times?.length)?.times?.[0]?.time,
      futureSchedules);

    if(!dateTimeIndexes) {
      return;
    }

    const [dayIndex, timeIndex] = dateTimeIndexes;
    setSelectedDayIndex(Math.max(dayIndex || 0, 0));
    setSelectedTimeIndex(Math.max(timeIndex || 0, 0));
  }, [fulfillmentData, selectedDiningOptionBehaviorSchedules, futureSchedules]);

  if(!futureSchedules?.length && !selectedDiningOptionBehaviorSchedules?.asapAvailableNow || !selectedFulfillmentType) {
    return null;
  }

  const normalizedDayIndex = Math.max(0, selectedDayIndex);
  const normalizedTimeIndex = Math.max(0, selectedTimeIndex);

  const day = futureSchedules?.length && futureSchedules[normalizedDayIndex]?.times.length
    ? futureSchedules[normalizedDayIndex]
    : futureSchedules?.find(date => date?.times?.length);
  const time = day?.times?.length ? day.times[normalizedTimeIndex] : null;

  const isDelivery = selectedDiningOptionBehavior === DiningOptionBehavior.Delivery;

  // If we get into this state, something has gone very wrong. We should
  // never have a scheduled order without a day or time
  if(selectedFulfillmentType === FulfillmentType.Future && (!day || !time)) {
    onClose();
    return null;
  }

  const futureArrivalString = isDelivery && cart?.deliveryQuoteTime ?
    `ASAP - ${cart.deliveryQuoteTime} - ${cart.deliveryQuoteTime + 5} minutes`
    : 'ASAP';
  const dayLabel = selectedFulfillmentType === FulfillmentType.Future && day ? toDayString(day.date) : 'Today';
  const timeLabel = selectedFulfillmentType === FulfillmentType.Future && time
    ? toLocalTime(time.time)
    : futureArrivalString;
  const timeOptions = futureSchedules[normalizedDayIndex]?.times || [];

  return (
    <>
      <div className="diningOptionsContent" role="form" data-testid="diningOptions">
        {removeItem && itemToRemove &&
          <div className="itemAddedToCartNote"><Image src="icons/check-mark.svg" />{`${itemToRemove.name ?? 'An item'} has been added to cart.`}</div>}
        {props.forceSelectMessage && <div className="error"><ErrorNotice>{props.forceSelectMessage}</ErrorNotice></div>}
        <div className={props.behaviorToggleClassName}>
          <DiningBehaviorToggle />
          {scheduledOrdersOnly && <div>You can only place scheduled orders right now.</div>}
          <AnimatedSection expanded={selectedDiningOptionBehavior === DiningOptionBehavior.Delivery}>
            <div className="prompt">Deliver to</div>
            <DeliveryAddressSelection />
          </AnimatedSection>
        </div>
        <AnimatedSection expanded={canScheduleSelectedBehavior}>
          <div className="prompt">Date</div>
          <div className="options">
            <DropDown label={dayLabel} withBorder testId="fulfillment-date-selector">
              {({ close }) => futureSchedules?.map((date, index) =>
                date.times.length ?
                  <DropDownOption className="option" testId="fulfillment-date-list-option" key={date.date}
                    onSelect={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedDayIndex(index);
                      setSelectedFulfillmentType(FulfillmentType.Future);
                      close();
                    }}>
                    {toDayString(date.date)}
                  </DropDownOption>
                  : null)}
            </DropDown>
          </div>
          <div className="prompt">{isDelivery ? 'Delivery ' : ''}Time</div>
          <div className="options">
            <DropDown label={timeLabel} withBorder disabled={timeOptions.length === 0} testId="fulfillment-time-selector">
              {({ close }) =>
                <>
                  {asapAvailable && selectedDayIndex <= 0 &&
                    <DropDownOption className="option" onSelect={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedFulfillmentType(FulfillmentType.Asap);
                      close();
                    }}>
                      {futureArrivalString}
                    </DropDownOption>}
                  {timeOptions.map((time, index) =>
                    <DropDownOption key={time.time} className="option" onSelect={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedTimeIndex(index);
                      setSelectedFulfillmentType(FulfillmentType.Future);
                      close();
                    }} >
                      {toLocalTime(time.time)}
                    </DropDownOption>)}
                </>}
            </DropDown>
          </div>
        </AnimatedSection>
      </div>
      <Button id="diningOptionSubmit" data-testid="diningOptionSubmit"
        variant={ButtonType.Primary}
        disabled={selectedDiningOptionBehavior === DiningOptionBehavior.Delivery && !validDeliveryAddress && !cart?.order?.deliveryInfo}
        onClick={async () => await submit()}>{canUpdate ? 'Update' : 'Close'}
      </Button>
      {removeItem && itemToRemove?.selectionGuid &&
        <Button variant={ButtonType.Text} id="diningOptionRemove" className="removeItem" onClick={() => {
          deleteFromCart(itemToRemove.selectionGuid!);
          tracker.track(
            'TEMP TBR - Removed item from cart',
            { timeBasedRules: itemInCartWithMaxLeadTime ? 'Minimum lead time' : itemsInCartWithPreorderRule.length ? 'Preorder' : 'Pickup window' }
          );
          onButtonClick?.();
          onClose();
        }}>
          Remove item
        </Button>}
      <AnimatedSection expanded={Boolean(fulfillmentError?.message)}>
        <div className="error"><ErrorNotice>{fulfillmentError?.message}</ErrorNotice></div>
      </AnimatedSection>
      {submitting && <LoadingSpinnerOverlay withBorderRadius />}
    </>
  );
};

export default DiningOptions;
