import { FieldType, ModuleField } from '@toasttab/sites-components';

import { ThemeTypeEnum, Image, CardImage, PaddingEnum } from 'src/apollo/sites';

export const getSectionPaddingModule = (editPath: string, padding: PaddingEnum | null = PaddingEnum.Medium): ModuleField => {
  const paddingValue = padding ?? PaddingEnum.Medium;
  const optionMap: Record<PaddingEnum, string> = {
    [PaddingEnum.None]: 'None',
    [PaddingEnum.Small]: 'Small',
    [PaddingEnum.Medium]: 'Medium',
    [PaddingEnum.Large]: 'Large'
  };
  return {
    path: `${editPath}.padding`,
    displayName: 'Vertical Spacing',
    type: FieldType.Enum,
    enums: Object.keys(optionMap).map((key: PaddingEnum) => ({ displayName: optionMap[key], value: key })),
    value: paddingValue
  };
};

export const getBackgroundColorModule = (editPath: string, backgroundColor?: string | null): ModuleField => {
  return {
    path: `${editPath}.backgroundColor`,
    displayName: 'Background Color',
    type: FieldType.BackgroundColor,
    value: backgroundColor
  };
};

export const getThemeModule = (editPath: string, theme?: ThemeTypeEnum | null): ModuleField => {
  return {
    path: `${editPath}.theme`,
    displayName: 'Style',
    type: FieldType.Enum,
    value: theme,
    enums: [
      { displayName: 'Default', value: null },
      { displayName: 'Square', value: ThemeTypeEnum.None },
      { displayName: 'Rounded', value: ThemeTypeEnum.Rounded },
      { displayName: 'Outlined', value: ThemeTypeEnum.Outlined },
      { displayName: 'Wide', value: ThemeTypeEnum.Wide },
      { displayName: 'Leaf', value: ThemeTypeEnum.HalfLeaf }
    ]
  };
};

type ImageModuleOptions = {
  displayNameOverride?: string
  adminLocation?: (ModuleField & { type: FieldType.Image })['adminLocation']
  deletable?: boolean
  deletableParent?: boolean
}

/**
 * Returns the editable schema for images.
 * @param imageObjectPath The path where the image resides.
 * @param imageObject The active image object.
 * @param options Additional options for editing the image in the admin.
 * @returns A list of ModuleFields required to edit an image.
 */
export const getImageModules = (
  imageObjectPath: string,
  imageObject: Image | CardImage | undefined,
  options?: ImageModuleOptions
): ModuleField[] => {
  const fields: (ModuleField & { path: string })[] = [
    {
      displayName: options?.displayNameOverride || 'Image',
      type: FieldType.Image,
      path: imageObjectPath,
      value: imageObject?.src,
      adminLocation: options?.adminLocation || 'form',
      deletable: options?.deletable === false ? false : true,
      deletableParent: options?.deletableParent === true ? true : false
    }
  ];
  return fields;
};
