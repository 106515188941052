import React, { useEffect, useMemo, useRef } from 'react';

import { LockLockedIcon, CloseIcon } from '@toasttab/buffet-pui-icons';
import classNames from 'classnames';

import { TagIcon } from 'src/public/components/default_template/offers/OffersIcons';
import Image from 'src/shared/components/common/Image';

export enum DiscountPillState {
  ACTIVE,
  LOCKED,
  INACTIVE,
}
export enum DiscountPillType {
  OFFER,
  REWARD,
  BUTTON
}
type DiscountPillProps = {
  text: string;
  onClick: () => void;
  pillKey?: string;
  state?: DiscountPillState
  type?: DiscountPillType;
  testId?: string;
};
const DiscountPill = ({ text, onClick, pillKey, state = DiscountPillState.INACTIVE, type = DiscountPillType.OFFER, testId }: DiscountPillProps) => {
  const pillTextRef = useRef<HTMLDivElement>(null);
  const icon = useMemo(() => {
    if(type === DiscountPillType.BUTTON) {
      return null;
    }
    switch(state) {
      case DiscountPillState.ACTIVE:
        return (
          <div className="checkContainer">
            <Image src="icons/check.svg" className="checkIcon" data-testid="checkmark" />
          </div>
        );
      case DiscountPillState.LOCKED:
        return <div className="tagIcon" data-testid="lock-icon"><LockLockedIcon /></div>;
      default:
        return <div className="tagIcon" data-testid="tag-icon"><TagIcon color="#646464" /></div>;
    }
  }, [state, type]);

  useEffect(() => {
    // changes the font size of all the pills if one of them is truncated
    if(pillTextRef.current &&
      (pillTextRef.current.offsetWidth < pillTextRef.current.scrollWidth ||
      pillTextRef.current.offsetHeight < pillTextRef.current.scrollHeight)) {
      document.querySelectorAll('.discountText').forEach(div => {
        div.classList.add('smallerText');
      });
    }
  });

  if(state === DiscountPillState.LOCKED && type === DiscountPillType.OFFER) {
    return null;
  }

  return (
    <button className={classNames(
      'discountPill',
      {
        active: state === DiscountPillState.ACTIVE,
        locked: state === DiscountPillState.LOCKED,
        buttonPill: type === DiscountPillType.BUTTON
      }
    )} onClick={onClick} key={pillKey} type="button" data-testid={testId} >
      {icon}
      <div className="discountText" ref={pillTextRef}>{text}</div>
      {state === DiscountPillState.ACTIVE &&
          <div className="activeActions">
            <div className="divider" />
            <CloseIcon size="xs" testId="close-icon" className="closeIcon" />
          </div>}
    </button>
  );
};

export default DiscountPill;
