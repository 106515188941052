import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ApolloProvider } from 'react-apollo';
import { useLocation, useParams } from 'react-router';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import { validate } from 'uuid';

import { DiningOptionBehavior, useRestaurantSchedulesQuery } from 'src/apollo/onlineOrdering';
import { BannerPlacement, CtaType, DisplayableMenuItemTag, LogoSizeOption, MenuFormatConfig } from 'src/apollo/sites';
import useElementInBounds from 'src/lib/js/hooks/useElementInBounds';
import useScrollDirection from 'src/lib/js/hooks/useScrollDirection';
import { useThrottledTracker } from 'src/lib/js/hooks/useTracker';
import { DebugPanel } from 'src/public/components/default_template/debug/DebugPanel';
import { schedulesByGuid } from 'src/public/components/online_ordering/scheduleUtils';
import { ScheduleType } from 'src/public/components/online_ordering/types';
import { useExperimentUserId } from 'src/shared/components/common/ab_testing/ABTestContext';
import { normalizeHtmlId } from 'src/shared/js/normalizeUtils';

import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import GlobalNav from 'shared/components/common/nav/Nav';
import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import { PopoverContextProvider, usePopoverContext } from 'shared/components/common/popover/PopoverContext';
import { RestaurantSiteContent, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';


import OrderPageSchema from 'public/components/default_template/OrderPageSchema';
import Footer from 'public/components/default_template/footer';
import StickyFooter from 'public/components/default_template/footer/sticky_footer/StickyFooter';
import HeroWithFulfillmentMap from 'public/components/default_template/hero_with_map/HeroWithMap';
import MenuNav, { RefMenu, useMenuNav } from 'public/components/default_template/menu_nav/MenuNav';
import { scrollToRef } from 'public/components/default_template/menu_nav/menuNavScrollUtils';
import { getTemplateClass } from 'public/components/default_template/menu_section/MenuItemCard';
import MenuSection, { MenuItem } from 'public/components/default_template/menu_section/MenuSection';
import PageMeta from 'public/components/default_template/meta/PageMeta';
import Nav from 'public/components/default_template/nav/Nav';
import { CART_POPOVER_CONTEXT_KEY } from 'public/components/default_template/online_ordering/cart/CartModal';
import { useSpiSdk, useSpi } from 'public/components/default_template/online_ordering/checkout/payment/useSpi';
import DiningOptionsModal from 'public/components/default_template/online_ordering/dining_options/DiningOptionsModal';
import MobileFulfillment from 'public/components/default_template/online_ordering/fulfillment/MobileFulfillment';
import DeeplinkItemModal from 'public/components/default_template/online_ordering/item_modal/DeeplinkItemModal';
import { ItemResolutionModal } from 'public/components/default_template/online_ordering/item_modal/ItemResolutionModal';
import { filterAndSortTags, OfferBadge } from 'public/components/default_template/online_ordering/item_tags/MenuItemTags';
import { MenuSearchContextProvider, useMenuSearchContext } from 'public/components/default_template/search';
import { CreditCardSurchargeDisclaimer } from 'public/components/default_template/surcharges/CreditCardSurchargeDisclaimer';
import { CartContextProvider, useCart } from 'public/components/online_ordering/CartContext';
import { DeliveryContextProvider } from 'public/components/online_ordering/DeliveryContext';
import { EditorFulfillmentContextProvider, FulfillmentContextProvider } from 'public/components/online_ordering/FulfillmentContext';
import OffersContextProvider, { useOffers } from 'public/components/online_ordering/OffersContext';
import { TimeBasedRuleContextProvider, useTimeBasedRules } from 'public/components/online_ordering/TimeBasedRuleContext';
import { UpsellContextProvider } from 'public/components/online_ordering/UpsellContext';
import { useOOMenus } from 'public/components/online_ordering/useOOMenu';

import { MenuHeader } from './MenuHeader';
import PageBanner from './banner/PageBanner';
import { CTAData } from './ctas';
import Offers from './offers/Offers';
import DiningBehaviorToggle from './online_ordering/dining_behavior_toggle/DiningBehaviorToggle';
import OrderAgain from './online_ordering/order_again/OrderAgain';
import Popups from './popups/Popups';
import { filterMenus, filterItems } from './search/searchFilterUtils';
import SpotlightBanner from './spotlight/SpotlightBanner';
import { useSpotlightContext } from './spotlight/SpotlightContext';

type Params = {
  menuGuid?: string;
  groupGuid?: string;
  itemSlug?: string;
  guid?: string;
}

type OrderPageContentProps = {
  scrollNavRef?: React.Ref<HTMLDivElement>;
  navOpen?: boolean;
  hasHero?: boolean;
  hideHero?: boolean;
  usingSecondaryNav?: boolean;
  menuGuid?: string;
  groupGuid?: string;
  itemGuid?: string;
};

const getGuidsFromHash = (hash?: string): { menuGuid?: string; groupGuid?: string; } => {
  if(hash) {
    // anchored deeplinks are two guids mashed together, one after another,
    // each prepended with a single 'd' character. We remove the leading '#',
    // then split them apart
    const menuGuid = hash.slice(2, hash.length / 2 + 1);
    const groupGuid = hash.slice(hash.length / 2 + 2);
    return {
      menuGuid: validate(menuGuid) ? menuGuid : undefined,
      groupGuid: validate(groupGuid) ? groupGuid : undefined
    };
  }

  return {};
};

export const OrderPageContent = ({ scrollNavRef, navOpen, hasHero, hideHero, usingSecondaryNav, menuGuid, groupGuid, itemGuid }: OrderPageContentProps) => {
  const { restaurant: siteRestaurant, selectedLocation, ooRestaurant: restaurant, ooPromoBanners } = useRestaurant();
  const { cart } = useCart();
  const { menuOffers } = useOffers();
  const [isMapOrModalOpen, setIsMapOrModalOpen] = useState(false);
  const hasScrolledToParam = useRef(false);
  const navRef = useRef<HTMLDivElement>(null);
  const { isHidden } = useSpotlightContext();
  const context = usePopoverContext(CART_POPOVER_CONTEXT_KEY);
  const displayableMenuItemTags = siteRestaurant.config.ooConfig?.displayableMenuItemTags as DisplayableMenuItemTag[];

  const { searchString } = useMenuSearchContext();

  const { isEditor, useEditableRef } = useEditor();
  const { editableRef } = useEditableRef<HTMLDivElement>({ name: 'menu', displayName: 'Menu', actions: [], schema: { fields: [] }, skipRef: navRef });

  const restaurantGuid = selectedLocation.externalId;
  const { menus, popularItems: unfilteredPopularItems } = useOOMenus({
    respectAvailability: !isEditor,
    hideOutOfStockItems: siteRestaurant.config.ooConfig?.hideOutOfStockItems ?? false
  });
  const {
    selectedMenuIndex,
    setSelectedMenuIndex,
    scrolledMenuIndex,
    setScrolledMenuIndex,
    selectedGuid,
    setSelectedGuid,
    scrolledGuid,
    setScrolledGuid,
    refMenuPages: unfilteredMenuPages,
    itemGuidsToItemsMap
  } = useMenuNav({ menus, page: '/order' });

  const deeplinkItem = itemGuid ? itemGuidsToItemsMap.get(itemGuid) : null;

  const refMenuPages = useMemo<RefMenu[]>(() => {
    return filterMenus(unfilteredMenuPages, searchString);
  }, [unfilteredMenuPages, searchString]);

  const popularItems = useMemo<MenuItem[]>(() => {
    const itemsWithFilteredTags = unfilteredPopularItems?.map(item => ({ ...item, itemTags: filterAndSortTags(item?.itemTags ?? [], displayableMenuItemTags) })) ?? [];
    return filterItems(itemsWithFilteredTags, searchString);
  }, [unfilteredPopularItems, searchString, displayableMenuItemTags]);

  const shouldShowMenuTitles = unfilteredMenuPages.length > 1;

  const pageNode = useCallback((node: any) => {
    if(node && menuGuid && !hasScrolledToParam.current) {
      const menuIndex = refMenuPages?.findIndex(menu => menu.guid === menuGuid);
      const pageRef = refMenuPages[menuIndex]?.ref;
      if(groupGuid) {
        const groupRef = refMenuPages[menuIndex]?.groups.find(group => group.guid === groupGuid)?.ref;
        if(groupRef) {
          setSelectedMenuIndex(menuIndex);
          scrollToRef(groupRef);
          hasScrolledToParam.current = true;
        }
        return;
      }
      if(menuIndex && pageRef && menuIndex !== selectedMenuIndex) {
        setSelectedMenuIndex(menuIndex);
        scrollToRef(pageRef);
        hasScrolledToParam.current = true;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refMenuPages]);

  const ooFormatConfig: MenuFormatConfig | undefined | null = siteRestaurant.config.ooConfig?.format;

  const heading = useMemo(() => restaurant?.whiteLabelName || 'Menu', [restaurant]);
  // TODO: Upcoming work will make the menu nav type configurable.  Until then, we'll hard-code it as necessary.
  // https://toasttab.atlassian.net/browse/WOO-772
  const sideBySide = false;

  const spotlight = siteRestaurant.content?.spotlightBanner;
  const navSpotlightEnabled = !isHidden && !!spotlight?.enabled && spotlight?.fixed === true;
  const hasNavSpotlight = navSpotlightEnabled && (spotlight?.placement === BannerPlacement.UnderHeader || spotlight?.placement === BannerPlacement.Top);
  const hasMenuSpotlight = navSpotlightEnabled && spotlight?.placement === BannerPlacement.UnderNav;

  const ooClient = useOOClient();

  const locationGuids = siteRestaurant.locations?.map(rx => rx.externalId) || [];
  const { data: scheduleData } = useRestaurantSchedulesQuery({
    variables: { restaurantGuids: locationGuids },
    skip: !locationGuids.length,
    client: ooClient,
    fetchPolicy: 'cache-first'
  });

  const locationSchedules = useMemo(() => schedulesByGuid(scheduleData), [scheduleData]) as { [guid: string]: ScheduleType } | undefined;
  const locationSchedule = locationSchedules && locationSchedules[selectedLocation.externalId] || undefined;
  const showFeaturedItems = popularItems.length > 0 && (
    selectedLocation.featuredItems?.enabled || selectedLocation.featuredItems?.enabled === undefined && !siteRestaurant.config.ooConfig?.popularItemsDisabled
  );

  return (
    <>
      <PageBanner ooPromoBanners={ooPromoBanners} spotlightConfig={restaurant?.spotlightConfig} />
      {!hideHero &&
        <HeroWithFulfillmentMap
          setIsMapOpen={setIsMapOrModalOpen}
          heading={heading}
          imgSrc={siteRestaurant.config.ooConfig?.heroImg}
          image={siteRestaurant.config.ooConfig?.heroImage}
          editPath="config.ooConfig" />}
      <div className="pageContent" ref={scrollNavRef} data-testid="order-page" role="main" id="main" aria-label="menu" tabIndex={0}>
        {!restaurant?.hasOnlineOrderingModule ?
          <div className="onlineOrderingUnavailable">Online ordering is currently unavailable. Please contact the restaurant to place an order.</div> :
          <div ref={editableRef}>
            <div className={classnames('navAndMenu', { sideBySide })}>
              <GlobalNav navType="stickyNav"
                className={classnames('secondaryNav', {
                  withoutHero: !hasHero,
                  withShadow: usingSecondaryNav,
                  globalNavOpen: navOpen || !usingSecondaryNav,
                  withNavSpotlight: hasNavSpotlight,
                  withMenuSpotlight: hasMenuSpotlight,
                  sideBySide
                })}>
                <div className="paddedContentWrapper hidden-md-up">
                  <div className={classnames('paddedContent', { withoutHero: !hasHero })}>
                    <MobileFulfillment expanded={navOpen || !usingSecondaryNav} />
                  </div>
                </div>
                <MenuNav
                  menus={refMenuPages}
                  selectedGroupGuid={selectedGuid}
                  setSelectedGroupGuid={setSelectedGuid}
                  selectedMenuIndex={selectedMenuIndex}
                  setSelectedMenuIndex={setSelectedMenuIndex}
                  menuConfig={ooFormatConfig}
                  navRef={navRef} />
                <SpotlightBanner placement={BannerPlacement.UnderNav} fixed={true} />
              </GlobalNav>
              <div className="menuSections">
                <SpotlightBanner placement={BannerPlacement.UnderNav} fixed={false} />
                <div className={classnames('paddedContentWrapper hidden-sm-down', { sideBySide })}>
                  <div className="paddedContent">
                    <div className="desktopFulfillmentWrapper">
                      <DiningBehaviorToggle saveChange />
                    </div>
                  </div>
                </div>
                <div className="paddedContentWrapper">
                  <div className="paddedContent">
                    <Offers />
                  </div>
                </div>
                { isEditor ? null : <OrderAgain /> }
                {showFeaturedItems ?
                  <div className={classnames('menuSectionWrapper', getTemplateClass(ooFormatConfig?.template), { columns: ooFormatConfig?.columns })}>
                    <div className={classnames('menuWrapper paddedContentWrapper', { sideBySide })}>
                      <div className="menu paddedContent">
                        <MenuSection
                          shortUrl={restaurant?.shortUrl}
                          restaurantGuid={restaurantGuid}
                          setIsMapOrModalOpen={setIsMapOrModalOpen}
                          isMapOrModalOpen={isMapOrModalOpen}
                          header="Featured Items"
                          orientation={siteRestaurant.config.ooConfig?.cardOrientation}
                          menuItems={popularItems}
                          menuConfig={{ format: ooFormatConfig, colors: siteRestaurant.config.ooConfig?.colors }} />
                      </div>
                    </div>
                  </div>
                  : null }
                <div className={classnames('menuSectionWrapper', getTemplateClass(ooFormatConfig?.template), { columns: ooFormatConfig?.columns })} ref={pageNode}>
                  {refMenuPages?.length
                    ? refMenuPages.map((page, pageIndex) =>
                      <div
                        role="tabpanel"
                        aria-labelledby={`${normalizeHtmlId(page.name)}-tab`}
                        className={classnames('menuWrapper paddedContentWrapper', { sideBySide })}
                        key={page.name}
                        ref={page.ref}
                        id={`${page.name} tab`}>
                        <div className="menu paddedContent">
                          <div className="headerWrapper">
                            <MenuHeader
                              header={ shouldShowMenuTitles ? page.name : null }
                              badge={(menuOffers[page.guid]?.length ?? 0) > 0 ? <OfferBadge text={(menuOffers[page.guid]?.length ?? 0) > 1 ? 'OFFERS' : 'OFFER'} /> : null} />
                          </div>
                          {page.groups.map(group =>
                            <MenuSection
                              key={group.guid}
                              shortUrl={restaurant?.shortUrl}
                              specialRequestsEnabled={restaurant?.specialRequestsConfig?.enabled}
                              specialRequestsPlaceholder={restaurant?.specialRequestsConfig?.placeholderMessage}
                              restaurantGuid={restaurantGuid}
                              setIsMapOrModalOpen={setIsMapOrModalOpen}
                              isMapOrModalOpen={isMapOrModalOpen}
                              onVisible={() => {
                                const newSection = group.guid;
                                if(scrolledGuid === selectedGuid) {
                                  setSelectedGuid(newSection);
                                }
                                if(selectedMenuIndex === scrolledMenuIndex) {
                                  setSelectedMenuIndex(pageIndex);
                                  setSelectedGuid(newSection);
                                }
                                setScrolledGuid(newSection);
                                setScrolledMenuIndex(pageIndex);
                              }}
                              ref={group.ref}
                              groupGuid={group.guid}
                              menuGuid={page.guid}
                              header={page.groups.length < 2 && group.name.toLowerCase() === page.name.toLowerCase() ? undefined : group.name}
                              description={group.description}
                              orientation={siteRestaurant.config.ooConfig?.cardOrientation}
                              menuItems={group.items}
                              menuConfig={{ format: ooFormatConfig, colors: siteRestaurant.config.ooConfig?.colors }} />)}
                          {pageIndex !== refMenuPages.length - 1 ? <hr className="sectionSeparator" /> : null}
                        </div>
                      </div>) :
                    <div className="paddedSection">
                      <MenuSection onVisible={() => {}} />
                      {searchString &&
                    <div style={{ paddingTop: '40px', paddingBottom: '80px' }}>
                      <h2>No results found. Please adjust your search term and try again.</h2>
                    </div>}
                    </div>}
                </div>
              </div>
            </div>
            {cart?.order?.numberOfSelections ?
              <StickyFooter primaryCta={{ text: `View order (${cart.order.numberOfSelections})`, type: 'customAction', onClick: () => context?.open() }} /> :
              null}
          </div>}
      </div>
      <OrderPageSchema restaurant={restaurant} site={siteRestaurant} selectedLocation={selectedLocation} locationSchedule={locationSchedule} menus={menus} />
      {/* Only necessary when the currently set schedule time has expired or delivery is selected without an address */}
      <DiningOptionsModal />
      {/* Only necessary when the guest has attempted to add an item to the cart that conflicts with the cart's current selections */}
      <ItemResolutionModal />
      {deeplinkItem && <DeeplinkItemModal item={deeplinkItem} />}
    </>
  );
};

const ParamDetectingOrderPage = () => {
  const params = useParams<Params>();
  const { hash } = useLocation();
  const anchorGuids = getGuidsFromHash(hash);
  return <MemoizedOrderPage params={{ menuGuid: params.menuGuid || anchorGuids.menuGuid, groupGuid: anchorGuids.groupGuid, itemSlug: params.itemSlug, guid: params.guid }} />;
};

const WrappedOrderPage = ({ params }: { params: Params }) => {
  const { track } = useThrottledTracker();
  const { restaurant: siteRestaurant, selectedLocation, ooRestaurant: restaurant, diningOptions, ooPromoBanners } = useRestaurant();
  const { rankedPromoOfferDiscounts, loading: offersLoading } = useOffers();
  const { scrollDirection } = useScrollDirection();
  const cartPopoverContext = usePopoverContext(CART_POPOVER_CONTEXT_KEY);
  const [scrollNavRef, inBounds] = useElementInBounds<HTMLDivElement>({ maxScrollY: 1 });
  const { isHidden } = useSpotlightContext();
  const isMobile = useIsMobile(ScreenWidth.SMALL);
  const { timeBasedRulesMap, loading: timeBasedRulesLoading } = useTimeBasedRules();
  const userId = useExperimentUserId();
  const orderPageViewedRef = useRef(false);
  const { surcharges } = useCart();
  const { spiSurchargingEnabled } = useSpi();

  useEffect(() => {
    if(typeof window !== 'undefined' && diningOptions && !timeBasedRulesLoading && !offersLoading && !orderPageViewedRef.current) {
      track('Order page view', {
        isAsapAvailable: !!diningOptions.some(option => option.asapSchedule?.availableNow),
        isScheduledAvailable: !!diningOptions.some(option => option.futureSchedule?.dates?.length),
        leadTimeRuleCount: Object.values(timeBasedRulesMap).filter(rules => rules.leadTimeRule).length,
        preorderRuleCount: Object.values(timeBasedRulesMap).filter(rules => rules.preorderRule).length,
        userExperimentId: userId,
        hasOOEntitlement: restaurant?.hasOnlineOrderingModule ?? true,
        numOffersPresent: rankedPromoOfferDiscounts?.length ?? 0,
        isDeliveryThresholdOfferPresent: Boolean(ooPromoBanners?.deliveryBanners.length)
      });
      orderPageViewedRef.current = true;
    }
    // diningOptions, timeBasedRulesMap, and rankedPromoOfferDiscounts get set when the data finishes loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diningOptions, timeBasedRulesMap, restaurant?.hasOnlineOrderingModule, rankedPromoOfferDiscounts, ooPromoBanners, timeBasedRulesLoading, offersLoading]);

  useEffect(() => {
    require('smoothscroll-polyfill').polyfill();
  }, []);

  const { menus, loading: loadingMenus } = useOOMenus({});

  const { refMenuPages } = useMenuNav({ menus, page: '/order' });

  const { title, description } = useMemo(() => {
    const formattedMenuGroups = refMenuPages.slice(0, 3)
      .flatMap(menu => menu.groups)
      .slice(0, 3)
      .map(group => group.name)
      .join(', ');
      // Source the location-specific page seo meta override if it exists
    const title = selectedLocation?.orderPageConfigOverride?.seoMeta?.title || siteRestaurant.config.ooConfig?.seoMeta?.title || 'Order Online';
    const description = selectedLocation?.orderPageConfigOverride?.seoMeta?.description || siteRestaurant.config.ooConfig?.seoMeta?.description ||
    `Order online from ${selectedLocation.name || restaurant?.name}, including ${formattedMenuGroups}. Get the best prices and service by ordering direct!`;
    return { title, description };
  }, [refMenuPages,
    restaurant?.name,
    selectedLocation.name,
    selectedLocation?.orderPageConfigOverride?.seoMeta?.description,
    siteRestaurant.config.ooConfig?.seoMeta?.description,
    selectedLocation?.orderPageConfigOverride?.seoMeta?.title,
    siteRestaurant.config.ooConfig?.seoMeta?.title]);

  if(!menus && loadingMenus) {
    return <LoadingSpinnerOverlay />;
  }
  if(!restaurant?.shortUrl) {
    return <NoMatch404 meta={siteRestaurant?.meta} />;
  }

  const { meta, content } = siteRestaurant;
  if(!content || !refMenuPages) {
    return <NoMatch404 meta={meta} />;
  }

  const usesOOBasic = siteRestaurant?.config?.hasFullCustomization === false; // only a value of 'false' indicates OO Basic tier; true, null, and undefined indicate OO Pro

  const { primaryCta, nonPrimaryCtas } = content as RestaurantSiteContent;
  const giftCardCTA: Omit<CTAData, 'submenus'> = {
    type: CtaType.Link,
    text: 'Buy Gift Cards',
    link: restaurant.giftCardLinks.purchaseLink
  };
  const newNonPrimaryCtas = restaurant.giftCardLinks.purchaseLink && usesOOBasic ? [...nonPrimaryCtas || [], giftCardCTA] : nonPrimaryCtas;
  const hasHero = !!siteRestaurant.config.ooConfig?.heroImage?.src;
  const usingSecondaryNav = inBounds;
  const navOpen = (scrollDirection === 'up' && !isMobile || cartPopoverContext?.isOpen) && usingSecondaryNav;

  const hasNavSpotlight = !isHidden && !!content.spotlightBanner?.enabled && content.spotlightBanner?.fixed === true
    && (content.spotlightBanner?.placement === BannerPlacement.UnderHeader || content.spotlightBanner?.placement === BannerPlacement.Top);

  return (
    <>
      <PageMeta title={title} description={description} />
      <div className="defaultTemplate">
        <Nav
          withShadow={!usingSecondaryNav}
          logoSrc={meta.icon}
          logoObject={meta.iconObject}
          logoSize={LogoSizeOption.Standard}
          navType="stickyNav"
          primaryCta={primaryCta}
          nonPrimaryCtas={newNonPrimaryCtas}
          withCart
          withOOOptions
          className={classnames({ collapsed: !navOpen && usingSecondaryNav, withNavSpotlight: hasNavSpotlight })} />
        <OrderPageContent
          scrollNavRef={scrollNavRef}
          navOpen={navOpen}
          hasHero={hasHero}
          usingSecondaryNav={usingSecondaryNav}
          menuGuid={params.menuGuid}
          groupGuid={params.groupGuid}
          itemGuid={params.itemSlug?.split('_')?.[1] ?? params.guid} />
        <Footer>
          <CreditCardSurchargeDisclaimer className="headerRule" spiSurchargingEnabled={spiSurchargingEnabled} surcharges={surcharges} />
        </Footer>
        <SpotlightBanner placement={BannerPlacement.Bottom} fixed="both" />
      </div>
    </>
  );
};

const MemoizedOrderPage = React.memo(WrappedOrderPage);

export const ContextualOrderPage = ({ children }: React.PropsWithChildren<{}>) => {
  const ooClient = useOOClient();
  const { isEditor } = useEditor();
  // Pre-loading the SDK in an attempt to improve the time it takes for the iframe to load on the checkout page.
  // It should be loaded on the Order page for all products, when the feature flag is enabled.
  useSpiSdk(isEditor);

  return (
    <ApolloProvider client={ooClient}>
      <CartContextProvider>
        <TimeBasedRuleContextProvider>
          <DeliveryContextProvider>
            <MenuSearchContextProvider>
              <OffersContextProvider>
                <UpsellContextProvider>
                  {isEditor
                    ?
                    <EditorFulfillmentContextProvider diningOptionBehavior={DiningOptionBehavior.TakeOut}>
                      <PopoverContextProvider contextKey={CART_POPOVER_CONTEXT_KEY}>
                        {children}
                      </PopoverContextProvider>
                    </EditorFulfillmentContextProvider>
                    :
                    <FulfillmentContextProvider>
                      <PopoverContextProvider contextKey={CART_POPOVER_CONTEXT_KEY}>
                        <Popups isOrderPage />
                        {children}
                        <DebugPanel />
                      </PopoverContextProvider>
                    </FulfillmentContextProvider>}
                </UpsellContextProvider>
              </OffersContextProvider>
            </MenuSearchContextProvider>
          </DeliveryContextProvider>
        </TimeBasedRuleContextProvider>
      </CartContextProvider>
    </ApolloProvider>
  );
};

const OrderPage = () => {
  const { isEditor } = useEditor();
  return (
    <ContextualOrderPage>
      {isEditor ? <MemoizedOrderPage params={{}} /> : <ParamDetectingOrderPage /> }
    </ContextualOrderPage>);
};
export default React.memo(OrderPage);
