import React, { useCallback, useState } from 'react';

import * as Sentry from '@sentry/react';

import { CustomerDocument, CustomerQuery, CustomerQueryResult, useCustomerQuery } from 'src/apollo/onlineOrdering';
import { getRegistrationSource } from 'src/shared/components/common/authentication';
import { formatPhoneNumber } from 'src/shared/components/common/form_input/PhoneInput';
import { useAuth } from 'src/vendor/do-secundo-guest-authentication';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';

import { CustomerContextCommonProvider } from './CustomerContextCommon';

export { CustomerContext } from './CustomerContextCommon';

export type Customer = CustomerQuery['customer'] & { __typename: 'Customer' } | undefined | null;

export const CustomerContextGiaProvider = (props: React.PropsWithChildren<{}>) => {
  const [loading, setLoading] = useState(false);
  const { startPasswordless, confirmVerificationCode, logOut, confirmPasswordless, setIsAuthenticated, isAuthenticated } = useAuth();
  const client = useOOClient();

  // use this for now but we may need to switch to get this directly from the auth libraries
  const { data: customerData, loading: loadingCustomer, refetch: refetchCustomer } = useCustomerQuery({
    ssr: false,
    fetchPolicy: 'cache-first',
    client,
    skip: !isAuthenticated
  });

  const customer = customerData?.customer as Customer;

  const passwordlessLogin = useCallback(async (phoneNumber: string) => {
    setLoading(true);
    let hasError = false;

    try {
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

      const { data } = await startPasswordless(formattedPhoneNumber, getRegistrationSource());

      if(data?.setupGuest.__typename !== 'SetupGuestSuccess') {
        hasError = true;
      }
    } catch(err) {
      hasError = true;
    }

    setLoading(false);
    return !hasError;
  }, [startPasswordless]);

  const completeSignup = useCallback(async (email: string, firstName: string, lastName: string) => {
    setLoading(true);
    let hasError = false;

    try {
      const { data } = await confirmPasswordless(
        getRegistrationSource(),
        {
          email,
          firstName,
          lastName
        }
      );

      if(data?.confirmGuest.__typename !== 'ConfirmGuestSuccess') {
        hasError = true;
      }
    } catch(err) {
      hasError = true;
    }

    setLoading(false);
    return !hasError;
  }, [confirmPasswordless]);

  const passwordlessConfirmCode = useCallback(async (_: string, code: string) => {
    setLoading(true);
    let customerGuid = null;

    try {
      const { data } = await confirmVerificationCode(code, getRegistrationSource());
      if(data?.confirmGuest.__typename === 'ConfirmGuestSuccess') {
        customerGuid = data.confirmGuest.guest.id;
        setIsAuthenticated(true);
      } else if(data?.confirmGuest.__typename === 'CompleteGuestProfileInputRequiredError') {
        // Legacy flow required a customerGuid but we don't get this if their profile is incomplete
        // so send a string for the check.
        customerGuid = 'getguestinfo';
        setIsAuthenticated(true);
      }
    } catch(err) {
      customerGuid = null;
    }

    setLoading(false);
    return customerGuid;
  }, [confirmVerificationCode, setIsAuthenticated]);

  // For use in instances where operations are contingent upon the existence of an account
  const fetchCustomer = useCallback(async () => {
    try {
      const { data } = await client.query<CustomerQueryResult['data']>({ query: CustomerDocument, fetchPolicy: 'network-only' });

      return (data?.customer || null) as Customer | null;
    } catch(err) {
      // Error expected, BFF throws when customer doesn't exist
      return null;
    }
  }, [client]);

  const pwlessLogout = useCallback(async () => {
    try {
      await logOut();
      client.writeQuery({
        query: CustomerDocument,
        data: { customer: null }
      });

      setIsAuthenticated(false);
      return true;
    } catch(err) {
      Sentry.captureException(`ERROR: passwordless logout error ${err}`);
      return false;
    }
  }, [client, logOut, setIsAuthenticated]);

  return (
    <CustomerContextCommonProvider context={{
      customer,
      loadingCustomer: loading || loadingCustomer,
      refetchCustomer,
      passwordlessLogin,
      passwordlessConfirmCode,
      completeSignup,
      pwlessLogout,
      fetchCustomer
    }}>
      {props.children}
    </CustomerContextCommonProvider>);
};
