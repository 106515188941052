import React, { useMemo } from 'react';

import Image from 'shared/components/common/Image';

import { rxLocationSearchUrl } from 'public/components/toast_local/common/searchUtils';

import { MsaType } from './msas.data';

type RXHotSpotsProps = {
  title: string,
  iconProps: object,
  elements: MsaType[]
};

const RXHotSpots = ({ title, iconProps, elements }: RXHotSpotsProps) => {
  const hotSpots = useMemo(() => {
    return elements.map(el => {
      const url = rxLocationSearchUrl({
        latitude: el.latitude,
        longitude: el.longitude,
        city: el.city,
        state: el.state
      });
      return (
        <div className="simple-listing pure-u-24-24 pure-u-md-12-24" key={el.city}>
          <div className="listing-content">
            <a href={url}><h3>{el.label || el.city}</h3></a>
            <p className="blurb">{el.description}</p>
          </div>
        </div>
      );
    });
  }, [elements]);

  return (
    <div className="rx-hot-spots pure-g">
      <div className="title-section pure-u-24-24 pure-u-lg-6-24">
        {iconProps && <Image {...iconProps} className="icon" />}
        <h2 className="txt-body-xxl">{title}</h2>
      </div>
      <div className="element-grid pure-u-24-24 pure-u-lg-18-24">
        <div className="pure-g hot-spots">{hotSpots}</div>
      </div>
    </div>
  );
};

export default RXHotSpots;
