import React from 'react';

import { ShowForUS } from 'src/shared/components/common/show_for_us/ShowForUS';

import { ToggleInput } from 'shared/components/common/forms';

export const SmsSubscriptionCheckbox = ({ isActive, setActive, disabled = false }: { isActive: boolean, setActive: (arg: boolean) => void, disabled?: boolean }) => {
  return (
    <div className="subscribeToSmsMarketingCheckbox">
      <ShowForUS>
        <ToggleInput
          type="checkbox"
          name="subscribeToSmsMarketing"
          id="subscribeToSmsMarketing"
          dataTestId="subscribeToSmsMarketing"
          disabled={disabled}
          checked={isActive}
          onChange={async (event: React.ChangeEvent<HTMLInputElement>) => setActive(event.target.checked)}
          onKeyDown={async (event: React.KeyboardEvent<HTMLInputElement>) => {
            if(event.key === 'Enter') {
              event.preventDefault();
              event.stopPropagation();
              setActive(!isActive);
            }
          }}>
          <p className="title">Receive exclusive offers</p>
        </ToggleInput>
        <div className="subtitle" role="contentinfo">
          I would like to receive recurring marketing texts including special offers from this Restaurant or restaurant Group
        </div>
      </ShowForUS>
    </div>
  );
};
