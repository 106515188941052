// For polyfilling:
import 'core-js';
import 'regenerator-runtime/runtime.js';
import 'react-hot-loader';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { hydrate } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

import * as Sentry from '@sentry/react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { createClient } from 'src/apollo/createClient';

import ErrorBoundary from 'shared/components/common/error_boundary/ErrorBoundary';

import ClientApp from 'public/components/app/ClientApp';

import { sentry, resources, ldClientId } from 'config';

// Kick off client-side monitoring
if(sentry.dsn && typeof window !== 'undefined') {
  Sentry.init({
    dsn: sentry.dsn,
    environment: sentry.env,
    // eslint-disable-next-line no-undef
    release: `toast-sites-web@${VERSION}`,
    // dont log errors from PetalBot
    beforeSend: ev => ev?.request?.headers['User-Agent'].includes('PetalBot') ? null : ev
  });
}

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: ldClientId,
    context: { key: 'sites-web' },
    options: { streaming: false }
  });

  hydrate(
    <ErrorBoundary>
      <LDProvider>
        <ApolloProvider client={createClient(resources.apiEndpoint, window.__APOLLO_STATE__, false, false, undefined, false, false, resources.clientQueryTimeoutMs)}>
          <HelmetProvider>
            <ClientApp />
          </HelmetProvider>
        </ApolloProvider>
      </LDProvider>
    </ErrorBoundary>,
    document.getElementById('content')
  );
};

renderApp();
