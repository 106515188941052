import fetch from 'cross-fetch';
import useSWR from 'swr';

import { useRestaurantByShortUrlQuery } from 'src/apollo/onlineOrdering';
import { Restaurant, RestaurantDataFragment, SitePagesDataFragment } from 'src/apollo/sites';
import { RequestContext } from 'src/lib/js/context';
import { ooScheduleToSitesSchedule } from 'src/lib/js/schedule';
import { formatImageURL } from 'src/lib/js/utilities';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import { OORestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { getSiteMgmtGroupGuid, getSiteShortUrl } from 'public/js/siteUtilities';

import { resources } from 'config';

export enum RxDataUsage {
  Required = 'REQUIRED',
  Optional = 'OPTIONAL',
  None = 'NONE'
}

type RestaurantLocation = {
  guid: string;
  shortUrl: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  latitude: number;
  longitude: number;
  viewOnlyMenuChannelGuid: string | null;
};

type Props = {
  isPageQuery: boolean;
  staticContext?: RequestContext;
  siteLoading?: boolean;
  siteRestaurant?: Restaurant;
  siteError?: any;
  shortUrlQueryString?: string | null;
};

const defaultConfig = (rxByShortUrl?: OORestaurant) => ({
  isMenuOnly: false,
  isOnlineOrderingOnly: true,
  isOnlineOrderingEnabled: rxByShortUrl?.onlineOrderingEnabled || false,
  hasFullCustomization: false
});

const defaultMeta = (rxByShortUrl?: OORestaurant) => ({
  icon: rxByShortUrl?.logoUrls?.small && formatImageURL(rxByShortUrl.logoUrls.small) || '',
  title: rxByShortUrl?.whiteLabelName || rxByShortUrl?.name || '',
  description: '',
  primaryColor: '#2B4FB9'
});

const defaultOOConfig = (rxByShortUrl?: OORestaurant) => {
  const image = rxByShortUrl?.bannerUrls?.medium ? formatImageURL(rxByShortUrl.bannerUrls.medium) : '';
  return {
    heroImg: image,
    heroImage: { src: image, displaySrc: image },
    format: {
      template: 'Classic',
      condenseImagelessGroups: false,
      roundedCorner: 'Exaggerated',
      dropShadow: 'Exaggerated'
    }
  };
};

// currently the default data only contains a single location for the Rx
const createDefaultData = (isPageQuery: boolean, rxByShortUrl?: OORestaurant, locationsData?: Array<RestaurantLocation> | null) =>
  isPageQuery ?
    {
      id: '-1',
      enabled: true,
      config: { ...defaultConfig(rxByShortUrl) },
      domainsV2: [],
      meta: { ...defaultMeta(rxByShortUrl) },
      locations: locationsData?.map(loc => ({ externalId: loc.guid })) || [{ externalId: rxByShortUrl?.guid || '' }],
      redirects: [],
      additionalPages: []
    } as SitePagesDataFragment :
    {
      id: '-1',
      externalId: '-1',
      externalRef: '',
      name: rxByShortUrl?.whiteLabelName || rxByShortUrl?.name || ((locationsData?.length || 0) > 0 ? locationsData![0]?.name : ''),
      enabled: true,
      onlineOrderingUrl: '',
      reservationsUrl: '',
      giftCardUrl: rxByShortUrl?.giftCardLinks?.purchaseLink || '',
      meta: { ...defaultMeta(rxByShortUrl) },
      config: {
        ...defaultConfig(rxByShortUrl),
        promoBannersDisabled: false,
        ooConfig: defaultOOConfig(rxByShortUrl),
        menuConfig: {}
      },
      domainsV2: [],
      content: {
        primaryCta: null,
        nonPrimaryCtas: [],
        footerConfig: {
          hideOOLink: true,
          hideMap: true
        }
      },
      locations: locationsData?.map((loc, i) =>
        ({
          id: `${-1 - i}`, // since these arent in the sites DB, set the IDs to -1, -2, -3...
          name: loc.name,
          externalId: loc.guid,
          shortUrl: loc.shortUrl,
          address1: loc.address,
          city: loc.city,
          state: loc.state,
          country: 'US', // not always true, but isnt returned by the CA BFF
          zipcode: loc.zip,
          phoneNumber: loc.phone,
          lat: loc.latitude,
          long: loc.longitude,
          schedule: [], // LocationSelection component fetches these for the LocationSelectionPage
          viewOnlyMenuChannelGuid: loc.viewOnlyMenuChannelGuid || null
        })) ||
        [{
          id: '-1',
          name: rxByShortUrl?.whiteLabelName || rxByShortUrl?.name || '',
          externalId: rxByShortUrl?.guid || '',
          shortUrl: rxByShortUrl?.shortUrl || '',
          address1: rxByShortUrl?.location.address1 || '',
          address2: rxByShortUrl?.location.address2 || '',
          city: rxByShortUrl?.location.city || '',
          state: rxByShortUrl?.location.state || '',
          country: 'US', // not always true, but isnt returned by the CA BFF
          zipcode: rxByShortUrl?.location.zip || '',
          phoneNumber: rxByShortUrl?.location.phone || '',
          lat: rxByShortUrl?.location.latitude || '',
          long: rxByShortUrl?.location.longitude || '',
          meta: {
            title: rxByShortUrl?.whiteLabelName || rxByShortUrl?.name || '',
            urls: [rxByShortUrl?.socialMediaLinks?.facebookLink, rxByShortUrl?.socialMediaLinks?.twitterLink, rxByShortUrl?.socialMediaLinks?.instagramLink].filter(l => l)
          },
          onlineOrderingUrl: rxByShortUrl?.onlineOrderingEnabled ? '' : '',
          reservationsUrl: '',
          schedule: ooScheduleToSitesSchedule(rxByShortUrl?.schedule.upcomingSchedules),
          viewOnlyMenuChannelGuid: null
        }],
      pixelsV2: {}
    } as RestaurantDataFragment;

const fetcher = (url: string | null) => url ? fetch(url).then(resp => resp.json()) : false;

// Load default Site data from the Consumer App BFF and the nearestrestaurants endpoint if
// Site data wasn't found in the Sites DB
const useDefaultSiteData = ({ mgmtGroupGuid, shortUrl, isPageQuery, skip }: { mgmtGroupGuid?: string, shortUrl?: string, isPageQuery: boolean, skip: boolean; }) => {
  const ooClient = useOOClient();

  // Since this skip logic depends on another query, during SSR this value may toggle from true to false.
  // However, the query doesn't get run in that case, so set skip to false during SSR as long as there is a shortUrl.
  const isSsr = typeof window === 'undefined';
  const { data: shortUrlRx, loading: shortUrlLoading, error: shortUrlError } = useRestaurantByShortUrlQuery({
    variables: { shortUrl: decodeURI(shortUrl || '') },
    client: ooClient,
    skip: !shortUrl || skip && !isSsr
  });
  const rxByShortUrl = shortUrlRx?.restaurantV2ByShortUrl as OORestaurant | undefined;

  // Only fetch data from the locations endpoint if there is a mgmt group guid.
  // Since we want to grab all Rx locations and don't care about how they are sorted, set default values for the lat, long, and diningOption.
  const skipFetch = skip || !mgmtGroupGuid;
  const { data, error: locationsError } = useSWR(
    skipFetch ? null : `${resources.apiEndpoint}/locations/nearestrestaurants/${mgmtGroupGuid}?lat=0&lon=0&unit=mi&diningOption=TAKE_OUT`,
    fetcher
  );
  const loadingLocations = !data && !skipFetch && !locationsError;
  const locationsData = data ? data as RestaurantLocation[] : null;

  const loading = shortUrlLoading || loadingLocations;
  const error = shortUrlError || locationsError;

  // Only create default data if data was found using the short URL or mgmt group guid
  const defaultData = !skip && !loading && (rxByShortUrl?.__typename === 'Restaurant' || locationsData?.length)
    ? createDefaultData(isPageQuery, rxByShortUrl, locationsData)
    : undefined;

  return { data: defaultData, loading, error };
};

// Use the siteRestaurant if it exists or if there is no shortUrl and no mgmtGrouGuid
const useDefaultSiteDataWrapper = ({ siteLoading, siteRestaurant, siteError, staticContext, isPageQuery, shortUrlQueryString }: Props) => {
  const mgmtGroupGuid = getSiteMgmtGroupGuid(staticContext);
  const shortUrl = getSiteShortUrl(staticContext, shortUrlQueryString);
  const skipDefault = siteLoading || !!siteRestaurant || !!siteError;
  const defaultData = useDefaultSiteData({ mgmtGroupGuid, shortUrl, isPageQuery, skip: skipDefault });

  return skipDefault ? { data: siteRestaurant, loading: siteLoading, error: siteError } : defaultData;
};

export default useDefaultSiteDataWrapper;
