import React from 'react';

import urljoin from 'url-join';

import Image from 'shared/components/common/Image';
import Link from 'shared/components/common/link';

import DemoButton from 'public/components/toast_local/common/DemoButton';
import { TOAST_LOCAL_PATH } from 'public/js/siteUtilities';

import { resources, server } from 'config';


const Nav = () => {
  return (
    <div className="toastLocalNav navWrapper">
      <div className="nav">
        <div className="left">
          <div data-testid="logoLink" nav-role="logo">
            <Link href={urljoin(`${server.protocol}://${resources.toastLocalHost}`, TOAST_LOCAL_PATH)} sameTab>
              <div className="logoWrapper">
                <Image alt="Toast logo" src="/icons/toast-logo-orange.svg" className="logo" eagerLoad />
              </div>
            </Link>
          </div>
        </div>
        <div className="middle"></div>
        <div className="right">
          <div className="posPromoMini hidden-sm-down ">
            <div className="message">Want to learn about our restaurant platform?</div>
            <DemoButton buttonLocation="nav_bar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
