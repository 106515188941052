import React from 'react';
import Skeleton from 'react-loading-skeleton';

import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

export type ItemPriceProps = {
  loading: boolean;
  title: string | React.ReactNode;
  amount?: number | null;
  negative?: boolean;
  amountPrefix?: string;
};

const ItemPrice = ({
  loading,
  title,
  amount,
  negative,
  amountPrefix = ''
}: ItemPriceProps) => {
  // show the amount if it is zero
  if(amount === undefined || amount === null) {
    return null;
  }

  // make negative if not already and let the formatter properly format negative currency
  const normalizedAmount = negative && amount > 0 ? amount * -1 : amount;

  return (
    <div data-testid={`${title}-item-test-id`} className={`cart-flex-row ${loading ? 'loading' : ''}`}>
      {loading ?
        <Skeleton width="100%" height="100%" /> :
        <>
          <span data-testid={'item-price-title'}>{title}</span>
          <span data-testid={'item-price-amount'}>{amountPrefix}{<FormattedPrice value={normalizedAmount} />}</span>
        </>}
    </div>
  );
};

export default ItemPrice;
