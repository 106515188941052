/* eslint { "react/no-unescaped-entities": 0, "max-len": 0 } */
import React, { useMemo } from 'react';

import { useEditor } from '@toasttab/sites-components';

import { PageConfig, UntappdMenuPage as UntappdMenuPagePageType } from 'src/apollo/sites';
import { RestaurantSiteContent, useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'src/shared/components/no_match_404/NoMatch404';

import Footer from 'public/components/default_template/footer';
import { EditPathRoot } from 'public/components/default_template/main_page/MainPage';
import Nav from 'public/components/default_template/nav/Nav';

type Props = {
  content?: UntappdMenuPagePageType;
  editPathRoot?: EditPathRoot;
  config?: PageConfig | null;
};

export const UntappdMenuPage = ({ content, config }: Props) => {
  const { restaurant } = useRestaurant();
  const { isEditor } = useEditor();
  const backgroundColorStyle = useMemo(() => config?.backgroundColor ? { backgroundColor: config?.backgroundColor } : {}, [config?.backgroundColor]);

  const { meta, content: rxContent } = restaurant;
  if(!rxContent) {
    return <NoMatch404 meta={meta} />;
  }

  if(isEditor && (!content || !content?.locationId && !content?.themeId)) return (
    <>
      <h1>You need to set up Untappd</h1>
    </>
  );

  const { locationId, themeId } = content!;

  if(!locationId || !themeId) return null;

  const { primaryCta, nonPrimaryCtas } = rxContent as RestaurantSiteContent;

  const iframeSrc = `https://business.untappd.com/embeds/iframes/${locationId}/${themeId}`;

  return (
    <div className="defaultTemplate betterTogetherTemplate" data-testid="better-together-page" style={backgroundColorStyle} id="main">
      <Nav navType="normal" logoSrc={meta.icon} logoObject={meta.iconObject} primaryCta={primaryCta} nonPrimaryCtas={nonPrimaryCtas} />
      <iframe src={iframeSrc} style={{ height: '2000px', border: 0 }} width="100%"></iframe>
      <Footer />
    </div>
  );
};

export type UntappdMenuPageType = UntappdMenuPagePageType;
