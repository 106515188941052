import { parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { DayOfWeek, DiningOptionBehavior, PickupWindowRule, ServicePeriod, UpcomingSchedules } from 'src/apollo/onlineOrdering';


// DayOfWeek values in order based on how new Date().getDay() returns days as numbers (0 = Sunday, 1 = Monday, etc)
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay#return_value
export const dayStrs = [
  DayOfWeek.Sunday,
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday
];

export const getPickupWindowRuleDuration = (pickupWindowRule: PickupWindowRule, dayOfWeek: DayOfWeek) => {
  const pickupDay = pickupWindowRule.days.find(day => day.dayOfWeek === dayOfWeek);
  const duration = pickupDay?.pickupWindows[pickupDay.pickupWindows.length - 1]?.duration; // Get the last pickup window duration
  return duration || 0;
};

export const getEarliestPickupTime = (servicePeriods: ServicePeriod[], scheduleDate: string, timeZone: string, pickUpWindowDuration: number) => {
  if(!servicePeriods || !servicePeriods.length) return false;

  const nonFormattedRestaurantClosingTime = servicePeriods?.[servicePeriods.length - 1]?.endTime;
  const formattedRestaurantClosingTime = zonedTimeToUtc(new Date(`${scheduleDate} ${nonFormattedRestaurantClosingTime}`), timeZone);
  return new Date(formattedRestaurantClosingTime.setHours(formattedRestaurantClosingTime.getHours() - pickUpWindowDuration));
};

// Validate that the fulfillment time for an item is within the pickup window
export const validateItemWithPickupWindowFulfillmentTime = (
  pickUpWindowDuration: number,
  scheduleDate: string,
  fulfillmentDateTime: string,
  behavior: DiningOptionBehavior,
  upcomingSchedules: UpcomingSchedules[],
  timeZone: string
) => {
  const dailyShedules = upcomingSchedules.find(upcomingSchedule => upcomingSchedule.behavior === behavior)?.dailySchedules;
  if(!dailyShedules) return false;

  const restaurantPeriods = dailyShedules.find(dailySchedule => dailySchedule.date === scheduleDate)?.servicePeriods;
  if(!restaurantPeriods) return false;

  const earliestPickupTime = getEarliestPickupTime(restaurantPeriods, scheduleDate, timeZone, pickUpWindowDuration);
  if(!earliestPickupTime) return false;

  return parseISO(fulfillmentDateTime) >= earliestPickupTime;
};

