import React from 'react';
import { Link } from 'react-router-dom';

import classnames from 'classnames';

import Image from 'shared/components/common/Image';

type Props = {
  cuisine: {
    name: string;
    value: string;
    icon: string;
  }
  subPath: string
  className: string
  onClickFilter: (evt: React.MouseEvent, cuisineKey: string) => void
}


const CuisineFilter = ({ onClickFilter, cuisine, subPath, className }: Props) => {
  const filterImage = cuisine.icon || 'toastLocal/cuisines/generic.svg';

  const onLinkClick = (event: React.MouseEvent, cuisineKey: string) => {
    event.persist();
    if(onClickFilter) {
      onClickFilter(event, cuisineKey);
    }
  };

  return (
    <div className={classnames('cuisine-filter', className)} title={cuisine.name}>
      <Link to={`${subPath}/best-${cuisine.value}`} onClick={event => onLinkClick(event, cuisine.value)}>
        <div className="cuisine-filter-content">
          <div className="filter-icon-wrapper">
            {filterImage && <Image src={filterImage} className="filter-icon" />}
          </div>
          <h5 className="filter-title">{cuisine.name}</h5>
        </div>
      </Link>
    </div>

  );
};

export default CuisineFilter;
