import React from 'react';

import LoyaltyRedemption from 'src/public/components/default_template/online_ordering/checkout/loyalty/LoyaltyRedemption';
import { useFlags } from 'src/shared/components/common/feature_flags/useFlags';
import { OORestaurant, useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import OffersSection from './OffersSection';

interface DiscountsSectionProps {
  testId?: string;
}

const DiscountsSection = ({ testId }: DiscountsSectionProps) => {
  const { ooRestaurant } = useRestaurant();
  const { oo3PLoyalty } = useFlags();
  if(!ooRestaurant) {
    return null;
  }
  return (
    <div data-testid={testId} className="discountsSection">
      <OffersSection />
      {showLoyaltySection(ooRestaurant, oo3PLoyalty) && <LoyaltyRedemption />}
    </div>
  );
};

function showLoyaltySection(ooRestaurant: OORestaurant, oo3PLoyalty: boolean) {
  const loyaltyConfig = ooRestaurant.loyaltyConfig;
  return loyaltyConfig?.loyaltyRedemptionEnabled && (loyaltyConfig?.programName !== 'INTEGRATION' || oo3PLoyalty);
}

export default DiscountsSection;
