import React, { useCallback, useMemo } from 'react';

import { DeliveryIcon, PickUpIcon, IconSize } from '@toasttab/buffet-pui-icons';

import { DiningOptionBehavior, FulfillmentType } from 'src/apollo/onlineOrdering';
import { useCart } from 'src/public/components/online_ordering/CartContext';
import { getQuoteTime } from 'src/public/components/online_ordering/fulfillmentUtils';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { getDiningBehaviorToggleLabel, toLocalTime } from 'src/shared/js/timeUtils';

import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import Toggle from 'shared/components/common/toggle/Toggle';

import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { useFulfillment } from 'public/components/online_ordering/FulfillmentContext';

export const getSubLabel = (isASAP: boolean, minTime: number | null, time?: string) => {
  if(isASAP) {
    return minTime ? getDiningBehaviorToggleLabel(minTime) : undefined;
  }

  return time ? toLocalTime(time) : undefined;
};

type Props = {
  saveChange?: boolean;
  className?: string;
  withSpinner?: boolean;
  iconSize?: IconSize;
}

const DiningBehaviorToggle = (props: Props) => {
  const { saveChange, className, withSpinner, iconSize } = props;
  const {
    takeoutEnabled,
    deliveryEnabled,
    canOrderTakeout,
    canOrderDelivery,
    fulfillmentData,
    selectedDiningOptionBehavior,
    setSelectedDiningOptionBehavior,
    diningOptionBehaviorAvailability,
    updateCartFulfillment,
    loadingFulfillment,
    setDeliveryAddressNeeded,
    guestSelectedFulfillmentTime
  } = useFulfillment();
  const { ooRestaurant } = useRestaurant();
  const { cart } = useCart();

  const fulfillmentType = fulfillmentData?.cartFulfillmentData.fulfillmentType;
  const isASAP = fulfillmentType === FulfillmentType.Asap;
  const fulfillmentTime = fulfillmentData?.cartFulfillmentData.fulfillmentDateTime || undefined;
  const pickupMinimumTime = useMemo(() => ooRestaurant ? getQuoteTime(DiningOptionBehavior.TakeOut, ooRestaurant, cart) : null, [cart, ooRestaurant]);
  const deliveryMinimumTime = useMemo(() => ooRestaurant ? getQuoteTime(DiningOptionBehavior.Delivery, ooRestaurant, cart) : null, [cart, ooRestaurant]);
  const scheduledDeliveryOnly = diningOptionBehaviorAvailability?.delivery?.scheduledAvailable && !diningOptionBehaviorAvailability.delivery.asapAvailable;
  const scheduledPickupOnly = diningOptionBehaviorAvailability?.takeout?.scheduledAvailable && !diningOptionBehaviorAvailability.takeout.asapAvailable;

  const { clearDeliveryAddress } = useDelivery();
  const onToggle = useCallback((value: DiningOptionBehavior) => {
    setSelectedDiningOptionBehavior(value);

    if(saveChange) {
      // delivery requires an address before the cart can be updated
      if(value === DiningOptionBehavior.Delivery) {
        setDeliveryAddressNeeded(true);
      } else if(fulfillmentData?.cartFulfillmentData) {
        clearDeliveryAddress();
        updateCartFulfillment({
          fulfillmentType: fulfillmentData.cartFulfillmentData.fulfillmentType,
          fulfillmentDateTime: fulfillmentData.cartFulfillmentData.fulfillmentDateTime,
          diningOptionBehavior: value,
          deliveryInfo: null
        });
      }
    }
  }, [setDeliveryAddressNeeded, clearDeliveryAddress, setSelectedDiningOptionBehavior, saveChange, updateCartFulfillment, fulfillmentData]);

  const toggleValue = useMemo(() => selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ? 'left' : 'right', [selectedDiningOptionBehavior]);

  // Hide the toggle if only one option is enabled, but show both if the Rx has both enabled, even if orders cannot be
  // created at this time (i.e. TakeOut is available now, but Delivery isnt)
  if(!takeoutEnabled || !deliveryEnabled) {
    return null;
  }

  return (
    <div className={className}>
      <Toggle
        className="fulfillmentToggleRedesign"
        value={toggleValue}
        onChange={onToggle}
        left={{
          name: `${scheduledDeliveryOnly && !guestSelectedFulfillmentTime ? 'Schedule ' : ''}Delivery`,
          value: DiningOptionBehavior.Delivery,
          disabled: !canOrderDelivery,
          icon: <DeliveryIcon size={iconSize} accessibility="decorative" />,
          subLabel: !(scheduledDeliveryOnly && !guestSelectedFulfillmentTime) ? getSubLabel(isASAP, deliveryMinimumTime, fulfillmentTime) : undefined,
          dataTestId: 'toggle-delivery'
        }}
        right={{
          name: `${scheduledPickupOnly && !guestSelectedFulfillmentTime ? 'Schedule ' : ''}Pickup`,
          value: DiningOptionBehavior.TakeOut,
          disabled: !canOrderTakeout,
          icon: <PickUpIcon size={iconSize} accessibility="decorative" />,
          subLabel: !(scheduledPickupOnly && !guestSelectedFulfillmentTime) ? getSubLabel(isASAP, pickupMinimumTime, fulfillmentTime) : undefined,
          dataTestId: 'toggle-pickup'
        }} />
      {withSpinner && loadingFulfillment && <LoadingSpinnerOverlay withBorderRadius />}
    </div>
  );
};

export default DiningBehaviorToggle;
