import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { MenuConfig, MenuTemplate, PlaceHolderImageGenerationEnum } from 'src/apollo/sites';
import { useThrottledTracker } from 'src/lib/js/hooks/useTracker';
import { formatImageURL, slugify } from 'src/lib/js/utilities';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

import Image from 'shared/components/common/Image';
import { useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import ItemModal from 'public/components/default_template/online_ordering/item_modal/ItemModal';
import ReadOnlyItemModal from 'public/components/default_template/online_ordering/item_modal/ReadOnlyItemModal';
import { MenuItemTags, OfferBadge } from 'public/components/default_template/online_ordering/item_tags/MenuItemTags';
import { SearchHighlightedText } from 'public/components/default_template/search';
import { useOffers } from 'public/components/online_ordering/OffersContext';
import { useTimeBasedRules } from 'public/components/online_ordering/TimeBasedRuleContext';

import { MenuItem } from './MenuSection';
import { PlaceHolderMenuImage } from './PlaceHolderMenuImage';
import { TemplateOverrides } from './menuStylingOverrides';

export const IMAGE_SUFFIX_REGEX = /\.(jpg|png)$/;

export type ToastImageSet = {
  xs?: string;
  small?: string;
  medium?: string;
  large?: string;
}

type MenuItemImageSet = {
  '140w'?: string
  '300w'?: string
  '600w'?: string
  '900w'?: string
}

// These source set URLs are served by the toast photo service
// and are guaranteed to exist
export const getMenuItemSrcSet = (images: ToastImageSet): MenuItemImageSet => {
  const imgSet: MenuItemImageSet = {};
  if(images.xs) { imgSet['140w'] = formatImageURL(images.xs); }
  if(images.small) { imgSet['300w'] = formatImageURL(images.small); }
  if(images.medium) { imgSet['600w'] = formatImageURL(images.medium); }
  if(images.large) { imgSet['900w'] = formatImageURL(images.large); }
  return imgSet;
};

export const getTemplateClass = (template: MenuTemplate | undefined | null): string => {
  if(!template) return '';
  const classStr = template ? template.toString() : '';
  if(!classStr) return '';
  return classStr.charAt(0).toLowerCase() + classStr.slice(1);
};

type Props = {
  item: MenuItem;
  menuGuid?: string | null;
  id?: string;
  setIsMapOrModalOpen?: (isOpen: boolean) => void;
  // True if the location map is open, or if any card has the modal open.
  isMapOrModalOpen?: boolean;
  // If true, formats the layout of the card as a popular item card.
  isPopularItem?: boolean;
  // True for ephemeral menu items that shouldn't be linked to.
  preventDeeplink?: boolean;
  isReadOnly?: boolean;
  menuConfig?: MenuConfig | null;
  rowHasDescription?: boolean;
  templateOverrides?: TemplateOverrides;
}

type ItemContentProps = {
  item: MenuItem;
  menuGuid?: string | null;
  // If true, formats the layout of the card as a popular item card.
  isPopularItem?: boolean;
  menuConfig?: MenuConfig | null;
  rowHasDescription?: boolean;
  templateOverrides?: TemplateOverrides;
}

type Params = {
  itemSlug?: string;
  itemGuid?: string;
  guid?: string;
}

// React Router doesn't provide functionality for changing the current route
// without rerendering, so we use native browser functionality
const replaceUrl = (newUrl: string) => {
  window.history.replaceState(null, '', newUrl);
};

export const ItemContent = (props: ItemContentProps) => {
  const { item, isPopularItem, menuConfig, templateOverrides } = props;
  const { restaurant: { config, name, meta: { fontFamily, backgroundColor: pageBackgroundColor } } } = useRestaurant();
  const { timeBasedRulesMap } = useTimeBasedRules();
  const { itemOffers, getOffersTextForEntity } = useOffers();
  const numItemOffers = item.guid ? itemOffers[item.guid]?.length ?? 0 : 0;
  const totalOffers = getOffersTextForEntity(item.guid, item.itemGroupGuid, props.menuGuid).length;
  const mobileOptimized = useIsMobile(ScreenWidth.EXTRA_SMALL) && props.templateOverrides?.enableMobileOptimization;
  const backgroundColor = (mobileOptimized ? pageBackgroundColor : menuConfig?.colors?.background) as string;

  const timeBasedRules = item.guid && timeBasedRulesMap[item.guid];
  const imgSrc = useMemo(() => item.imageUrls?.medium || item.imageUrls?.sm || item.imageUrls?.large || item.imageUrls?.xs, [item.imageUrls]);

  const menuTemplateConfig = templateOverrides?.menuTemplateOverride;
  const itemDescriptionTextColor = menuConfig?.colors?.secondaryText;
  const contentStyle = itemDescriptionTextColor ? { color: itemDescriptionTextColor } : {};

  const ooBasic = config.isOnlineOrderingOnly && config.hasFullCustomization === false;
  const sideImage = !menuTemplateConfig || menuTemplateConfig === MenuTemplate.SideImage;
  const squareImage = sideImage && (ooBasic || menuConfig?.format?.squareImages);
  const imageRatioClass = templateOverrides?.imageRatio || (squareImage ? 'squareImage' : '');
  const hideDescription = templateOverrides?.hideMenuItemDescription || false;

  const itemImage = useMemo(() => {
    const className = classnames('itemImage', imageRatioClass, { hideImage: props.templateOverrides?.hideImages, outOfStock: item.outOfStock });
    if(item?.image) {
      return <Image alt="" src={item.image} className={className} data-testid={item.name + '-image'} />;
    } else if(imgSrc && item.imageUrls) {
      return <Image
        alt=""
        src={formatImageURL(imgSrc)}
        srcSet={getMenuItemSrcSet(item.imageUrls)}
        className={className}
        data-testid={item.name + 'image'} />;
    } else if(props.templateOverrides?.placeholderImageConfig.generatePlaceholderImage === PlaceHolderImageGenerationEnum.RepeatedText) {
      const imageSize = menuTemplateConfig === MenuTemplate.TopImageV2 ? 420 : 200;
      const text = props.templateOverrides?.placeholderImageConfig.placeholderImageText || name;
      return (
        <div className={classnames(className, 'placeholderImg')}>
          <PlaceHolderMenuImage
            text={text}
            textColor={'rgba(0, 0, 0, .15)'}
            fontFamily={fontFamily?.name}
            fontSize={'16px'}
            rotation={-29}
            height={imageSize}
            width={imageSize}
            alpha={0.2} />
        </div>
      );
    } else {
      return null;
    }
  },
  [
    imageRatioClass,
    props.templateOverrides?.hideImages,
    props.templateOverrides?.placeholderImageConfig.generatePlaceholderImage,
    props.templateOverrides?.placeholderImageConfig.placeholderImageText,
    item.outOfStock,
    item.image,
    item.imageUrls,
    item.name,
    imgSrc,
    menuTemplateConfig,
    name,
    fontFamily?.name
  ]);

  if(isPopularItem) {
    const backgroundImage = !item.image && item.imageUrls?.raw ? formatImageURL(item.imageUrls.raw) : null;
    const popularItemBackground = backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : null;

    return (
      <div className={classnames('popularItem', popularItemBackground ? 'backgroundImage' : '' )} style={popularItemBackground ? popularItemBackground : undefined}>
        <div className="itemName">{props.item.name}</div>
      </div>
    );
  }
  if(menuTemplateConfig === MenuTemplate.Condensed || menuTemplateConfig === MenuTemplate.ExtraCondensed || menuTemplateConfig === MenuTemplate.Stacked) {
    return (
      <div className={classnames('itemInfo', getTemplateClass(menuTemplateConfig))}>
        <div className="itemHeadAvailDesc">
          <div className="itemHeader">
            <SearchHighlightedText className="headerText" text={item.name ?? ''} />
          </div>
          {item.description && !hideDescription &&
            <div className="itemContent">
              <p style={contentStyle} className="itemDescription">
                <SearchHighlightedText text={item.description} />
              </p>
            </div>}
          <div className="priceAvailability">
            <PriceLine item={item} />
            <MenuItemTags item={item} backgroundColor={backgroundColor} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {numItemOffers > 0 && !item.outOfStock && !mobileOptimized ?
        <OfferBadge
          text={totalOffers > 1 ? 'OFFERS' : 'OFFER'}
          className={classnames('offerBadge', menuTemplateConfig === MenuTemplate.LeftImageV2 ? 'leftAlign' : 'rightAlign')} /> :
        null}
      {itemImage}
      <div className={classnames('itemInfo', { outOfStock: item.outOfStock })}>
        <div className={classnames('itemHeader', { hasTag: timeBasedRules })}>
          <SearchHighlightedText className="headerText" text={item.name ?? ''} />
          {/* Description added to the header so we can clamp ellipsis to 3 lines as a group */}
          {item.description && !hideDescription &&
            <div className="itemContent">
              <p data-testid="item-content-description" style={contentStyle} className="itemDescription">
                <SearchHighlightedText text={item.description ?? ''} />
              </p>
            </div>}
        </div>
        <div className="priceAvailability">
          <PriceLine item={item} />
          <MenuItemTags item={item} backgroundColor={backgroundColor} numOffers={mobileOptimized && numItemOffers > 0 ? totalOffers : 0} />
        </div>
      </div>
    </>
  );
};

const PriceLine = ({ item }: {item: MenuItem}) => {
  const perUnitString = item.usesFractionalQuantity && item.unitOfMeasure ? `/${item.unitOfMeasure.toLowerCase()}` : '';
  if(!item.price && !item.prices?.length) {
    return null;
  }
  const value = item.price ?? item.prices?.sort((a, b) => a - b)[0]!;
  const unit = item.price && item.prices?.length && item.prices.length > 1 ? '+' : '';
  return (
    <span className={classnames('price', { outOfStock: item.outOfStock })} data-testid={`price-${item.guid}`}>
      <FormattedPrice value={value} />
      {unit}{perUnitString}
    </span>
  );
};

const MenuItemCard = (props: Props) => {
  const { isEditor } = useEditor();

  return isEditor
    ? <MenuItemCardBase {...props} preventDeeplink={true} disableClickEvents={true} />
    : <MenuItemCardWithRouterParamsAndTracker {...props} />;
};

const MenuItemCardWithRouterParamsAndTracker = (props: Props) => {
  const params = useParams<Params>();
  const { track } = useThrottledTracker();
  return <MenuItemCardBase {...props} track={track} params={params} />;
};

type BaseProps = Props & {
  track?: (name: any, data: any) => void
  params?: Params
  disableClickEvents?: boolean
};

const menuItemStyle = (item: MenuItem, menuConfig?: MenuConfig | null) => {
  return {
    ...menuConfig?.colors?.background && { backgroundColor: menuConfig.colors.background },
    ...menuConfig?.colors?.primaryText && !item.outOfStock && { color: menuConfig.colors.primaryText }
  };
};


const MenuItemCardBase = ({ disableClickEvents = false, ...props } : BaseProps) => {
  const track = props.track;
  const { item, setIsMapOrModalOpen, isReadOnly } = props;
  const { ooRestaurant, selectedLocation } = useRestaurant();
  const { orderPath } = useRestaurantRoutes();
  const { isOpen, onOpen, onClose } = useModal();

  const openModal = useCallback(() => {
    onOpen();
    if(setIsMapOrModalOpen) {
      setIsMapOrModalOpen(true);
    }
  }, [onOpen, setIsMapOrModalOpen]);

  const closeModal = useCallback(() => {
    onClose();
    if(setIsMapOrModalOpen) {
      setIsMapOrModalOpen(false);
    }
    if(!isReadOnly) {
      replaceUrl(orderPath);
    }
  }, [onClose, setIsMapOrModalOpen, isReadOnly, orderPath]);

  const onItemClick = useCallback(e => {
    onItemClickImpl(e, isReadOnly, item, track, orderPath, openModal);
  }, [openModal, item, track, isReadOnly, orderPath]);

  if(!item.name) {
    return null;
  }

  const itemStyle = menuItemStyle(item, props.menuConfig);

  const itemReference = !isReadOnly ? menuItemPath(orderPath, item.name, item.guid) : undefined;
  const templateOverrides = props.templateOverrides;
  const menuTemplateConfig = templateOverrides?.menuTemplateOverride;

  return (
    <>
      {isReadOnly ?
        <ReadOnlyItemModal
          withTransitions
          isOpen={isOpen}
          onClose={closeModal}
          name={item.name}
          description={item.description}
          itemTags={item.itemTags}
          image={item?.image ? item.image : item.imageUrls?.raw ? item.imageUrls?.raw : undefined} />
        :
        <ItemModal
          withTransitions
          isOpen={isOpen}
          onClose={closeModal}
          restaurantGuid={selectedLocation.externalId}
          shortUrl={ooRestaurant?.shortUrl}
          specialRequestsEnabled={ooRestaurant?.specialRequestsConfig.enabled}
          specialRequestsPlaceholder={ooRestaurant?.specialRequestsConfig.placeholderMessage}
          itemGroupGuid={item.itemGroupGuid}
          itemMenuGuid={props.menuGuid}
          itemGuid={item.guid}
          itemTags={item.itemTags}
          shouldShowHighlights /> }
      {isReadOnly && (menuTemplateConfig === MenuTemplate.Condensed || menuTemplateConfig === MenuTemplate.ExtraCondensed || menuTemplateConfig === MenuTemplate.Stacked) ?
        <li key={item.guid || item.name}
          style={{ ...itemStyle, pointerEvents: disableClickEvents ? 'none' : 'auto' }}
          className={classnames('item',
            templateOverrides?.cardOrientation,
            getTemplateClass(menuTemplateConfig))}>
          <ItemContent key={item.guid}
            item={item}
            menuGuid={props.menuGuid}
            isPopularItem={props.isPopularItem}
            menuConfig={props.menuConfig}
            templateOverrides={templateOverrides} />
        </li>
        :
        <li role="listitem"
          key={item.guid || item.name}
          className={classnames('item',
            templateOverrides?.cardOrientation,
            templateOverrides?.backgroundShade,
            templateOverrides?.borderStroke,
            templateOverrides?.roundedCorner,
            templateOverrides?.dropShadow,
            getTemplateClass(menuTemplateConfig),
            {
              clickable: true,
              outOfStock: !!item.outOfStock,
              mobileOptimized: templateOverrides?.enableMobileOptimization
            })}
          style={{ ...itemStyle, borderColor: templateOverrides?.borderColor, pointerEvents: disableClickEvents ? 'none' : 'auto' }}>
          <a
            data-testid={'add-to-cart-' + item.guid}
            href={itemReference}
            id={props.id}
            onClick={onItemClick}
            onKeyDown={onItemClick}
            tabIndex={0}
            style={{ ...itemStyle, pointerEvents: disableClickEvents ? 'none' : 'auto' }}>
            <ItemContent
              key={item.guid}
              item={item}
              menuGuid={props.menuGuid}
              isPopularItem={props.isPopularItem}
              menuConfig={props.menuConfig}
              rowHasDescription={props.rowHasDescription}
              templateOverrides={templateOverrides} />
          </a>
        </li>}
    </>
  );
};

export const onItemClickImpl = (
  e: React.KeyboardEvent, isReadOnly: boolean | undefined, item: MenuItem, track: ((name: any, data: any) => void) | undefined, orderPath: string, openModal : () => void
) => {
  if(e.type === 'keydown' && (e.code !== 'Space' && e.code !== 'Enter')) {
    return; // only continue if the spacebar or enter key is pressed
  }
  e.preventDefault();
  e.stopPropagation();
  track?.('Clicked menu item', {
    guid: item.guid,
    name: item.name || 'No name',
    readOnly: !!isReadOnly,
    outOfStock: !!item.outOfStock,
    hasImage: Boolean(item.image),
    descLength: item.description ? item.description.length : 0,
    timeBasedRule: item.timeBasedRules?.leadTimeRule ? 'Minimum lead time' : item.timeBasedRules?.preorderRule ? 'Preorder' : null
  });
  if(!item.outOfStock) {
    openModal();
    if(!isReadOnly) {
      replaceUrl(menuItemPath(orderPath, item.name || '', item.guid));
    }
  }
};


export const menuItemPath = (orderPath: string, itemName: string, itemGuid: string | null) => `${orderPath}/item-${slugify(itemName)}_${itemGuid}`;

export default MenuItemCard;
