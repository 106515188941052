import React, { useMemo, useState } from 'react';

import classnames from 'classnames';

import { RestaurantSearchInput, RxSearchQueryVariables, useRxSearchQuery } from 'src/apollo/onlineOrdering';
import { useQueryString } from 'src/lib/js/hooks/useQueryString';
import { LoadingSpinner } from 'src/shared/components/common/loading_spinner/LoadingSpinner';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';

import UhOh from 'shared/components/uh_oh/UhOh';

import { View } from 'public/components/pages/location_selection_page/LocationSelection';
import { ListViewIcon, MapViewIcon } from 'public/components/pages/location_selection_page/LocationSelectionIcons';
import ToastLocalHelmet from 'public/components/toast_local/common/ToastLocalHelmet';
import RXCard from 'public/components/toast_local/rx_card/RXCard';

import { server } from 'config';

type Props = {
  title?: string;
  dish?: string;
  numPerPage?: number;
  utmContent?: string;
  className?: string;
  mapView?: boolean;
}

const PaginatedRXs = ({ title, numPerPage = 50, utmContent, className, mapView }: Props) => {
  const parsedQS = useQueryString(['filters'], ['filters']);
  const ooClient = useOOClient();
  const [view, setView] = useState<View>(View.List);

  const variables: RxSearchQueryVariables = useMemo(() => {
    const vars: RestaurantSearchInput = { limit: numPerPage };
    if(parsedQS.filters?.userLat && parsedQS.filters?.userLong) {
      vars.latitude = parsedQS.filters.userLat;
      vars.longitude = parsedQS.filters.userLong;
    }

    let query = '';
    if(parsedQS.filters?.cuisine) {
      query += parsedQS.filters.cuisine;
    }
    if(parsedQS.filters?.search) {
      query += ' ' + parsedQS.filters.search;
    }

    vars.q = query;

    return { input: vars };
  }, [numPerPage, parsedQS]);

  const { loading, error, data } = useRxSearchQuery({ variables, client: ooClient });

  const restaurants = data && data.findConsumerAppRestaurants.__typename === 'RestaurantSearchResults' && data.findConsumerAppRestaurants.results?.length ?
    data.findConsumerAppRestaurants.results
    : null;

  const mapGuids = useMemo(() => restaurants?.map(loc => `guids=${loc.guid}`).join('&'), [restaurants]);
  const firstRx = restaurants && restaurants[0] ? restaurants[0] : undefined;
  const mapShortUrl = firstRx?.shortUrl ? encodeURIComponent(firstRx.shortUrl) : undefined;
  const selectedGuid = firstRx?.guid;

  const mapSrc = useMemo(() =>
    `${server.protocol}://${server.fullHost}/map?shortUrl=${mapShortUrl}&sourceId=ToastLocalSearch&order=` +
    `&${mapGuids}&selectedGuid=${selectedGuid}&selectedDiningOption=&style=locationDetails`,
  [mapGuids, mapShortUrl, selectedGuid]);

  if(loading) return <LoadingSpinner />;
  if(error) return <UhOh />;

  return (
    <>
      <ToastLocalHelmet />
      <div className={classnames('paginated-rxs', className)}>
        <h2 className="rx-listing-title">{title}</h2>

        {mapView && view === View.Map && restaurants?.length ?
          <div className="restaurantsMap">
            <iframe title="Google Maps" width="100%" height="100%" loading="eager"
              className="locationMap"
              src={mapSrc}>
            </iframe>
          </div> :
          <div className="restaurants pure-g">
            {restaurants ?
              restaurants.map((rx: any) => <RXCard
                restaurant={rx} utmContent={utmContent} className="pure-u-24-24 pure-u-md-12-24 pure-u-lg-8-24" key={rx.guid} />)
              : <div className="pure-u-24-24"><h2>0 restaurants available. Please broaden your search to have better results.</h2></div>}
          </div>}

        {mapView &&
          <button className="viewButton" onClick={() => setView(view === View.Map ? View.List : View.Map)}>
            {view === View.Map ? <ListViewIcon color="#FF4C00" /> : <MapViewIcon color="#FF4C00" /> }
            <span>{`Show ${view === View.Map ? 'list' : 'map'}`}</span>
          </button>}
      </div>
    </>
  );
};

export default PaginatedRXs;
