import React, { createContext, useContext, useMemo } from 'react';
import { withRouter } from 'react-router';

import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';

import { RequestContextProps } from 'src/lib/js/context';

import { resources } from 'config';


type OOClientContextProps = {
  client: ApolloClient<any> | ((host: string) => ApolloClient<any>);
};

export type OOClientType = {
  client: ApolloClient<any>;
  refreshClient?: () => void;
};

declare global {
  interface Window {
    __OO_STATE__: NormalizedCacheObject | undefined;
  }
}

export const OOClientContext = createContext<OOClientType | undefined>(undefined);

export const WrappedOOClientProvider = (props: React.PropsWithChildren<Partial<RequestContextProps> & OOClientContextProps>) => {
  const { client: propsClient } = props;

  const client = useMemo(
    () => typeof propsClient === 'function' ? propsClient(resources.federatedProxyHost) : propsClient,
    [propsClient]
  );
  return (
    <OOClientContext.Provider value={{ client }}>
      {props.children}
    </OOClientContext.Provider>
  );
};


export const OOClientProvider = withRouter<RequestContextProps & OOClientContextProps, React.ComponentType<RequestContextProps & OOClientContextProps>>(WrappedOOClientProvider);

export const useOOClient = () => {
  const context = useContext(OOClientContext);
  if(!context) {
    throw new Error('useOOClient must be used with an OOClientProvider');
  }

  return context.client;
};
