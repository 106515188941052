import React, { createContext, useContext } from 'react';

import { CustomerQuery } from 'src/apollo/onlineOrdering';

export interface CustomerContextCommonType {
  customer?: Customer | null;
  loadingCustomer: boolean;
  refetchCustomer: () => void;
  refreshAuthToken?: () => void;
  fetchCustomer: () => Promise<Customer | null>;
  passwordlessLogin: (phoneNumber: string) => Promise<boolean>;
  passwordlessConfirmCode: (phoneNumber: string, code: string) => Promise<string | null>;
  completeSignup: (email: string, firstName: string, lastName: string) => Promise<boolean>;
  pwlessLogout: () => Promise<boolean>;
}

export interface CustomerContextType extends CustomerContextCommonType {
  savedAddressUsed: boolean;
  setSavedAddressUsed: (savedAddressUsed: boolean) => void;
}

export type Customer = CustomerQuery['customer'] & { __typename: 'Customer' } | undefined | null;

export const CustomerContext = createContext<CustomerContextType | undefined>(undefined);

export const CustomerContextCommonProvider = (props: React.PropsWithChildren<{context: CustomerContextCommonType}>) => {
  const [savedAddressUsed, setSavedAddressUsed] = React.useState(false);

  const context = {
    ...props.context,
    savedAddressUsed,
    setSavedAddressUsed
  };

  return (
    <CustomerContext.Provider value={context}>
      {props.children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => {
  const context = useContext(CustomerContext);
  if(!context) {
    throw new Error('useCustomer must be used within a CustomerContextProvider');
  }

  return context;
};
