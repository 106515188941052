import React, { useCallback, useEffect, useRef, useState } from 'react';

import parseISO from 'date-fns/parseISO';

import { DiningOptionBehavior, FulfillmentType } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';

import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { toLocalDateTime } from 'shared/js/timeUtils';

import DiningOptions from 'public/components/default_template/online_ordering/dining_options/DiningOptions';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { getDateTimeIndexes, useFulfillment } from 'public/components/online_ordering/FulfillmentContext';
import { ItemWithTimeBasedRule, useTimeBasedRules } from 'public/components/online_ordering/TimeBasedRuleContext';

export const formatFulfillmentDateTime = (fulfillmentDateTime?: string | null ) => {
  const parsedTime = fulfillmentDateTime ? parseISO(fulfillmentDateTime) : null;
  return parsedTime ? toLocalDateTime(parsedTime) : 'ASAP';
};

type Props = {
  children?: (openModal: () => Promise<void>) => React.ReactNode;
};

const DiningOptionsModal = (props : React.PropsWithChildren<Props>) => {
  const { isOpen, onClose, onOpen } = useModal();
  const { refetchOORestaurant } = useRestaurant();
  const { cart } = useCart();
  const { canOrderTakeout, fulfillmentData, deliveryAddressNeeded, selectedDiningOptionBehavior } = useFulfillment();
  const { clearDeliveryValidationError, hasCheckedStoredLocation } = useDelivery();
  const { itemInCartWithMaxLeadTime, itemsInCartWithPreorderRule, itemsInCartWithPickupWindowRule, getEarliestFulfillmentTime, validateItemFulfillmentTime } = useTimeBasedRules();
  const [forceSelectMessage, setForceSelectMessage] = useState('');
  const [removeItem, setRemoveItem] = useState(false);
  const tracker = useTracker();
  const maxLeadTimeRef = useRef<ItemWithTimeBasedRule | undefined>(undefined);

  const openModal = useCallback(async () => {
    // refetch the rx in case something has changed (e.g. some times in futureScheduleDates may be outdated)
    await refetchOORestaurant();
    onOpen();
  }, [refetchOORestaurant, onOpen]);

  useEffect(() => {
    if(hasCheckedStoredLocation &&
      (deliveryAddressNeeded || fulfillmentData?.cartFulfillmentData.diningOptionBehavior === DiningOptionBehavior.Delivery && !fulfillmentData.cartFulfillmentData.deliveryInfo)) {
      setForceSelectMessage(`Please enter a delivery address${canOrderTakeout ? ' or change your order to Pickup' : ''}`);
      return;
    } else {
      setForceSelectMessage('');
    }

    if(fulfillmentData?.cartFulfillmentData.fulfillmentType === FulfillmentType.Asap && !fulfillmentData?.scheduleData.asapAvailableNow && fulfillmentData?.scheduleData?.futureScheduleDates.length) {
      const diningBehavior = fulfillmentData?.cartFulfillmentData.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'Delivery' : 'Pickup';
      setForceSelectMessage(`${diningBehavior} is not available right now. Please schedule your order.`);
      return;
    }

    const [dayIndex, timeIndex] = getDateTimeIndexes(fulfillmentData?.cartFulfillmentData.fulfillmentDateTime, fulfillmentData?.scheduleData?.futureScheduleDates);
    if(dayIndex != null && timeIndex != null && (dayIndex < 0 || timeIndex < 0)) {
      setForceSelectMessage('Your order time is no longer available. Please re-schedule your order.');
      return;
    }

    const leadTimeInvalid = itemInCartWithMaxLeadTime?.itemGuid !== maxLeadTimeRef.current?.itemGuid;
    // The last item in the list should be the most recently added one, which is the one we need to validate
    const preorderInvalid = !validateItemFulfillmentTime(itemsInCartWithPreorderRule[itemsInCartWithPreorderRule.length - 1]?.itemGuid);
    const pickupWindowInvalid = !validateItemFulfillmentTime(itemsInCartWithPickupWindowRule[itemsInCartWithPickupWindowRule.length - 1]?.itemGuid);

    if(leadTimeInvalid || preorderInvalid || pickupWindowInvalid) {
      if(leadTimeInvalid) {
        maxLeadTimeRef.current = itemInCartWithMaxLeadTime;
        const fulfillmentTime = fulfillmentData?.cartFulfillmentData.fulfillmentDateTime;
        if(fulfillmentTime && parseISO(fulfillmentTime) > getEarliestFulfillmentTime(itemInCartWithMaxLeadTime?.itemGuid, selectedDiningOptionBehavior)) {
        // If the chosen fulfillment time is still later than the earliest possible fulfillment time for the current cart, we don't need to open the modal
          return;
        }
      }
      const diningBehavior = selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ? 'delivery' : 'pickup';
      const selectMessage = `Please update your ${diningBehavior} time to continue ordering or remove the item from your cart.`;
      setForceSelectMessage(selectMessage);
      setRemoveItem(true);
      tracker.track('TEMP TBR - Fulfillment modal opened', { timeBasedRules: leadTimeInvalid ? 'Minimum lead time' : preorderInvalid ? 'Preorder' : 'Pickup window' });
      return;
    }

  // only run when fulfillment data changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentData, deliveryAddressNeeded, hasCheckedStoredLocation, cart, selectedDiningOptionBehavior, itemInCartWithMaxLeadTime, itemsInCartWithPreorderRule, itemsInCartWithPickupWindowRule]);

  useEffect(() => {
    if(forceSelectMessage) {
      openModal();
    }
  }, [forceSelectMessage, openModal, canOrderTakeout]);

  const handleButtonClick = () => {
    setForceSelectMessage('');
    setRemoveItem(false);
  };

  return (
    <>
      <Modal testId="dining-options-modal" isOpen={isOpen} preventOverlayClose={forceSelectMessage !== ''} onClose={() => {
        clearDeliveryValidationError();
        onClose();
      }} >
        <ModalOverlay fadeIn />
        <ModalContent contentClassName="diningOptionsModalContent" fadeIn ariaLabelledBy="dining-options-modal-header">
          <div className="header">
            <h3 id="dining-options-modal-header">Order details</h3>
            {!forceSelectMessage && <ModalCloseButton />}
          </div>
          <DiningOptions forceSelectMessage={forceSelectMessage} onButtonClick={handleButtonClick} removeItem={removeItem} />
        </ModalContent>
      </Modal>
      {props.children && props.children(openModal)}
    </>
  );
};

export default DiningOptionsModal;
