import React from 'react';

import Image from 'shared/components/common/Image';

import { Restaurant } from './RXCard';

type Props = {
  config?: Restaurant
}

const RXConfig = ({ config }: Props) =>
  <div className="rx-config">
    {config && config.hasOnlineOrderingModule ? <Image src="toastLocal/icons/takeout-gray.svg" alt="Takeout" /> : null}
    {config && config.offPremDerivedDeliveryEnabled ? <Image src="toastLocal/icons/delivery-gray.svg" alt="Delivery" /> : null}
  </div>;

export default RXConfig;
