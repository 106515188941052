import React, { useRef } from 'react';

import { useEditor } from '@toasttab/sites-components';

import { EmailMarketingPage, LoyaltySignUpPage, PageConfig } from 'src/apollo/sites';

import { _Image } from 'shared/components/common/editable_image/EditableImage';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import { SubmitState } from 'shared/js/hooks/useSubmissionState';

import { BetterTogetherSection } from 'public/components/default_template/better_together_section/BetterTogetherSection';
import { EditPathRoot } from 'public/components/default_template/main_page/MainPage';
import BetterTogetherWrapper from 'public/components/pages/better_together/BetterTogetherWrapper';

type Props = {
  content?: LoyaltySignUpPage | EmailMarketingPage;
  editPathRoot?: EditPathRoot
  config?: PageConfig | null;
  onSubmit: (data: any) => void
  useEmail: boolean;
  header: string;
  subheader: string;
  isSubheaderEditable?: boolean;
  disclaimer: any;
  successLine1: string;
  successLine2: string;
  errorLine1: string;
  errorLine2: string;
  submitState: SubmitState
}

const BetterTogetherPage = (props: Props) => {
  const { restaurant } = useRestaurant();
  const { useEditableRef } = useEditor();
  const ignoreClickRef = useRef(null);
  const { editableRef: editableBetterTogetherRef } = useEditableRef<HTMLDivElement>({
    name: 'better together page',
    actions: [],
    skipRef: ignoreClickRef
  });

  const { meta, content } = restaurant;
  if(!content) {
    return <NoMatch404 meta={meta} />;
  }

  const sectionHeaders: Pick<React.ComponentProps<typeof BetterTogetherSection>, 'header' | 'subheader'> = {
    header: {
      content: props.header,
      editPath: `${props.editPathRoot}.content.headerText`
    },
    subheader: {
      content: props.subheader,
      editPath: props.isSubheaderEditable ? `${props.editPathRoot}.content.subheaderText` : undefined
    }
  };

  return (
    <BetterTogetherWrapper content={props.content} editPathRoot={props.editPathRoot} config={props.config}>
      <BetterTogetherSection {...props} {...sectionHeaders} inputType={props.useEmail ? 'email' : 'phone'} editableRef={editableBetterTogetherRef} />
    </BetterTogetherWrapper>
  );
};

export default BetterTogetherPage;
