import React from 'react';

import { LoyaltyRedemption } from 'src/apollo/onlineOrdering';

import Image from 'shared/components/common/Image';


type LockedLoyaltyRedemptionProps = {
  redemption?: LoyaltyRedemption;
}

// Displayed for 1PL&3PL when the user does not have any redemptions. Shows their progress to the next reward.
export const LockedLoyaltyRedemption = ({ redemption } : LockedLoyaltyRedemptionProps) => {
  const messages = redemption?.currentState?.messages;
  if(!messages || !messages.rewardValueMessage) {
    return <></>;
  }
  const { rewardValueMessage, rewardLabelMessage } = messages;

  return (
    <button disabled className="lockedRedemptionButton" data-testid="locked-redemption">
      <div className="lockedLoyaltyRedemption">
        <Image alt="Locked Loyalty reward" src={'icons/lock-locked.svg'} data-testid="locked-loyalty-icon" />
        <div className="textContainer">
          <span className="lockedHeader" data-testid="locked-header">{rewardValueMessage}</span>
          {rewardLabelMessage && <span className="message" data-testid="locked-message">{rewardLabelMessage}</span>}
        </div>
      </div>
    </button>
  );
};
