import React from 'react';
import { TailSpin } from 'react-loader-spinner';

import { DEFAULT_COLORS } from 'src/public/components/default_template/meta/StyleMeta';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';


export const LoadingSpinner = ({ color = DEFAULT_COLORS.primary, size }: { color?: string; size?: string }) => {
  return <TailSpin color={color} width={size} height={size} />;
};

const ContextualLoadingSpinner = ({ size }: {size?: string}) => {
  const { restaurant: { meta: { primaryColor } } } = useRestaurant();

  return <TailSpin color={primaryColor} width={size} height={size} />;
};

export default ContextualLoadingSpinner;
