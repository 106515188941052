import React, { createContext, useContext, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useMarketingSubscriptionMutation, useMarketingConfigQuery } from 'src/apollo/onlineOrdering';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

export const SUBSCRIPTION_SOURCE = 'ONLINE';
export const SUBSCRIPTION_CHANNEL = 'SMS';

export interface MarketingContextType {
  smsAccountEnabled: boolean;
  createMarketingSubscriptionRequest: (args: CreateMarketingSubscriptionInput) => void;
  subscribeToSmsMarketing: boolean;
  setSubscribeToSmsMarketing: React.Dispatch<React.SetStateAction<boolean>>;
}

type CreateMarketingSubscriptionInput = {
  phoneNumber: string,
  firstName: string,
  lastName: string
}

export const MarketingContext = createContext<MarketingContextType | undefined>(undefined);


export const MarketingContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { ooRestaurant, restaurant } = useRestaurant();
  const { mktSmsOoSubscription } = useFlags();
  const [subscribeToSmsMarketing, setSubscribeToSmsMarketing] = useState(false);
  const [createMarketingSubscriptionRequest] = useMarketingSubscriptionMutation();
  const restaurantGuid = ooRestaurant?.guid || '';

  const { data, refetch, error } = useMarketingConfigQuery({
    variables: {
      input: {
        restaurantGuid,
        managementSetGuid: restaurant.externalId
      }
    }
  });

  useState(() => {
    if(error) {
      refetch();
    }
  });

  const smsAccountEnabled = data?.marketing.marketingConfig.__typename === 'MarketingConfigQuerySuccessResponse' ? data.marketing.marketingConfig.smsAccountEnabled : false;

  const subscribeCustomer = ({ phoneNumber, firstName, lastName }: CreateMarketingSubscriptionInput) => {
    createMarketingSubscriptionRequest({
      variables: {
        input: {
          managementSetGuid: restaurant.externalId,
          restaurantGuid,
          subscriptionRequest: {
            source: SUBSCRIPTION_SOURCE,
            firstName: firstName,
            lastName: lastName,
            restaurantGuid,
            subscriptions: [{
              channel: SUBSCRIPTION_CHANNEL,
              value: phoneNumber
            }]
          }
        }
      }
    });
  };

  const context = {
    smsAccountEnabled: smsAccountEnabled && mktSmsOoSubscription,
    createMarketingSubscriptionRequest: subscribeCustomer,
    subscribeToSmsMarketing,
    setSubscribeToSmsMarketing
  };

  return (
    <MarketingContext.Provider value={context}>
      {props.children}
    </MarketingContext.Provider>
  );
};

export const useMarketing = () => {
  const context = useContext(MarketingContext);
  if(!context) {
    throw new Error('useMarketing must be used within a MarketingContextProvider');
  }

  return context;
};
