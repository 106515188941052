import React, { useMemo } from 'react';
import { useParams, withRouter } from 'react-router';

import isLatLong from 'validator/lib/isLatLong';

import { useRestaurantProfileQuery, useRxSearchQuery, RestaurantSearchInput, RxSearchQueryVariables } from 'src/apollo/onlineOrdering';
import { useToastLocalConfigQuery } from 'src/apollo/sites';
import { RequestContextProps } from 'src/lib/js/context';
import useTracker from 'src/lib/js/hooks/useTracker';
import { getPath } from 'src/lib/js/utilities';
import { ExperimentContextProvider } from 'src/shared/components/common/ab_testing/ABTestContext';
import { DebugContextProvider } from 'src/shared/components/common/debug/DebugContext';

import RedirectWithStatus from 'shared/components/common/RedirectWithStatus';
import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import RestaurantContextProvider from 'shared/components/common/restaurant_context/RestaurantContext';
import { RestaurantRoutesContextProvider } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import { RxDataUsage } from 'shared/js/hooks/useDefaultSiteData';

import Meta from 'public/components/default_template/meta/Meta';
import { RestaurantProfileRestaurantType, RestaurantSearchResultType } from 'public/components/default_template/restaurant_profile/profileUtils';
import { StructuredDataProvider } from 'public/components/seo/context';
import { TOAST_LOCAL_ORDER_PATH, TOAST_LOCAL_PATH } from 'public/js/siteUtilities';

import { resources } from 'config';

export type WrappedProfilePageProps = {
  restaurantProfileData: RestaurantProfileRestaurantType
  nearbyRxs?: RestaurantSearchResultType
};

const RestaurantProfilePageWrapper = ({ staticContext, children }: { children: ((p: WrappedProfilePageProps) => React.ReactNode) } & RequestContextProps) => {
  const { slug, rxGuid }: { slug: string, rxGuid: string } = useParams();
  const path = getPath(staticContext);
  const tracker = useTracker();

  const { data: tlData, loading: tlLoading } = useToastLocalConfigQuery(
    { variables: { restaurantGuid: rxGuid } }
  );

  const ooClient = useOOClient();
  const { data, loading } = useRestaurantProfileQuery({
    variables: { restaurantGuid: rxGuid },
    client: ooClient
  });

  const variables: RxSearchQueryVariables = useMemo(() => {
    const vars: RestaurantSearchInput = { limit: 7 };

    let query = '';
    if(data?.restaurantV2?.__typename === 'Restaurant') {
      const rx = data.restaurantV2;
      vars.latitude = rx.location.latitude;
      vars.longitude = rx.location.longitude;
      query += rx.cuisineType || '';
    }

    vars.q = query;
    return { input: vars };
  }, [data]);

  const validLatLong = useMemo(() =>
    data?.restaurantV2?.__typename === 'Restaurant'
    && data.restaurantV2.location.latitude
    && data.restaurantV2.location.longitude
    && isLatLong(`(${data.restaurantV2.location.latitude},${data.restaurantV2.location.longitude})`),
  [data?.restaurantV2]);

  const { data: searchData, loading: searchLoading } = useRxSearchQuery({
    variables,
    skip: !validLatLong,
    client: ooClient
  });

  if(tlLoading || loading || searchLoading) {
    return null;
  }

  if(!tlData?.toastLocalConfig?.enabled || !data?.restaurantV2 || data.restaurantV2.__typename !== 'Restaurant') {
    return <NoMatch404 />;
  } else if(data.restaurantV2.shortUrl && data.restaurantV2.hasOnlineOrderingModule && !path.startsWith(TOAST_LOCAL_ORDER_PATH)) {
    return <RedirectWithStatus to={`${TOAST_LOCAL_ORDER_PATH}/${data.restaurantV2.shortUrl}/r-${data.restaurantV2.guid}`} status={301} />;
  }

  const nearbyRxs = searchData?.findConsumerAppRestaurants?.__typename === 'RestaurantSearchResults'
    ? searchData.findConsumerAppRestaurants.results.filter(rx => rx.guid !== rxGuid)
    : undefined;

  const homePath = `${TOAST_LOCAL_PATH}`;
  const profilePath = `${TOAST_LOCAL_ORDER_PATH}/${slug}/r-${rxGuid}`;
  const accountPath = `${TOAST_LOCAL_ORDER_PATH}/${slug}/r-${rxGuid}/account`;
  const profilePathPattern = `${TOAST_LOCAL_ORDER_PATH}/:slug/r-:rxGuid`;

  return (
    <ExperimentContextProvider onVariantSelected={(experimentName, variantName) => {
      tracker.register({ [experimentName]: variantName });
    }}>
      <Meta title={`${data.restaurantV2.name} | Toast`} primaryColor="#FF4C00" icon="icons/favicon-32x32.png" domain={resources.toastLocalHost} />
      <DebugContextProvider>
        <RestaurantContextProvider ooUsage={RxDataUsage.Required} sitesUsage={RxDataUsage.None} isEditor={false}>
          <RestaurantRoutesContextProvider shortUrl={slug} homePath={homePath} orderPath={profilePath}
            orderPathPattern={profilePathPattern} checkoutPathPrefix={profilePath} confirmPathPrefix={profilePath}
            accountPath={accountPath}>
            <StructuredDataProvider>
              {children({ restaurantProfileData: data.restaurantV2 as RestaurantProfileRestaurantType, nearbyRxs })}
            </StructuredDataProvider>
          </RestaurantRoutesContextProvider>
        </RestaurantContextProvider>
      </DebugContextProvider>
    </ExperimentContextProvider>
  );
};

export default withRouter<RequestContextProps, React.ComponentType<RequestContextProps>>(RestaurantProfilePageWrapper);
