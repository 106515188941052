import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import { throttle } from 'lodash';

import { DisplayableMenuItemTag, MenuFormatConfig, MenuSearchMode, MenuTemplate } from 'src/apollo/sites';

import { Modal, ModalCloseButton, ModalOverlay } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { normalizeHtmlId } from 'shared/js/normalizeUtils';

import { DropdownTriangle, LeftArrow, NavControl, RightArrow } from 'public/components/default_template/menu_nav/Icons';
import { ScrollDirection, scrollToRef, useHorizontalScroll } from 'public/components/default_template/menu_nav/menuNavScrollUtils';
import { MenuItem } from 'public/components/default_template/menu_section/MenuSection';
import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import { filterAndSortTags } from 'public/components/default_template/online_ordering/item_tags/MenuItemTags';
import { SearchInput, useMenuSearchContext } from 'public/components/default_template/search';
import ToastProduct from 'public/components/online_ordering/ToastProduct';


export type RefMenuGroup = {
  name: string;
  description?: string | null;
  ref: React.RefObject<HTMLDivElement>;
  id: string;
  guid: string;
  items: MenuItem[]
}

export type RefMenu = {
  name: string;
  guid: string;
  ref: React.RefObject<HTMLDivElement>;
  groups: RefMenuGroup[];
}

type MenuGroup = {
  name?: string | null;
  guid?: string | null;
  items?: (MenuItem | Partial<MenuItem> | null)[] | null;
}

type Menu = {
  name?: string | null;
  id?: string;
  groups?: (MenuGroup | null)[] | null;
  guid?: string | null;
}

type HookProps = {
  menus?: Menu[] | null;
  page: '/order' | '/menu';
}

export const useMenuNav = (props: HookProps) => {
  const { restaurant } = useRestaurant();
  const displayableTags = useMemo(
    () => restaurant.config[props.page === '/order' ? 'ooConfig' : 'menuConfig']?.displayableMenuItemTags as DisplayableMenuItemTag[],
    [restaurant.config, props.page]
  );
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [scrolledMenuIndex, setScrolledMenuIndex] = useState(0);
  const [scrolledGuid, setScrolledGuid] = useState<string>();
  const [selectedGuid, setSelectedGuid] = useState<string>();
  // This is so we can render an item modal for any item with just the guid from the Order Page.  It doesn't
  // matter that we overwrite the map with a different version of the item if there are duplicates across groups,
  // we just need a valid group ID and valid list of itemTags for each item guid.
  const itemGuidsToItemsMap = new Map<string, MenuItem>();

  const refMenuPages: RefMenu[] = useMemo(() => props.menus ?
    props.menus.map(menu => ({
      name: menu.name as string,
      guid: menu.guid || menu.id || menu.name as string,
      ref: React.createRef<HTMLDivElement>(),
      groups: menu.groups?.filter(group => group?.items?.length)
        .map((group: any) => ({
          ...group,
          ref: React.createRef<HTMLDivElement>(),
          guid: group.guid || group.name,
          id: group.guid || group.name,
          items: group.items.map((item: MenuItem) => {
            if(item.guid && group.guid) itemGuidsToItemsMap.set(item.guid, item);
            return {
              ...item,
              itemTags: filterAndSortTags(item?.itemTags ?? [], displayableTags)
            };
          })
        })) || []
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    : [], [props.menus, displayableTags]);

  return {
    selectedMenuIndex,
    setSelectedMenuIndex,
    scrolledMenuIndex,
    setScrolledMenuIndex,
    scrolledGuid,
    setScrolledGuid,
    selectedGuid,
    setSelectedGuid,
    refMenuPages,
    itemGuidsToItemsMap
  };
};

const MenuNav = ({
  menus,
  selectedMenuIndex,
  setSelectedMenuIndex,
  selectedGroupGuid,
  setSelectedGroupGuid,
  menuConfig,
  navRef
}: {
  menus: RefMenu[];
  selectedMenuIndex: number;
  setSelectedMenuIndex: (index: number) => void;
  selectedGroupGuid?: string;
  setSelectedGroupGuid: (guid: string) => void;
  menuConfig?: MenuFormatConfig | null;
  navRef?: React.RefObject<HTMLDivElement>;
}) => {
  const {
    scrollContainerRef,
    showLeftArrow,
    showRightArrow,
    scroll,
    scrollEvent
  } = useHorizontalScroll();
  const {
    restaurant,
    toastProduct
  } = useRestaurant();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { canUseSearch } = useMenuSearchContext();
  const showBorders = toastProduct === ToastProduct.OOPro || toastProduct === ToastProduct.Sites;

  useEffect(() => {
    scrollEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menus.length]);

  if(menuConfig?.hideMenuNav && menuConfig?.hideSubMenuNav) {
    if(menuConfig.menuSearchMode === MenuSearchMode.Visible) {
      // menus are configured to be hidden but search is still visible. clear out menus so they don't show
      menus = [];
    } else {
      // all nav items are configured to be hidden, <TopMenuNav /> shouldn't show at all
      return null;
    }
  }

  const hasMenuNav = menus.length > 1 && !menuConfig?.hideMenuNav;
  const hasSubMenuNav = !menuConfig?.hideSubMenuNav;

  const hasHero = !!restaurant.config.ooConfig?.heroImage?.src;
  const primaryColor = restaurant.meta.primaryColor ?? DEFAULT_COLORS.primary;

  // There are 3 cases to account for:
  // 1. There is a menuNav and a subMenuNav, in which case we'll render a nav with dropdowns
  // 2. There is only a menuNav and no subMenuNav, in which case we'll render the menus without dropdowns
  // 3. There is only a subMenuNav and no menuNav, in which case we redefine the menus as the submenus and render them without dropdowns
  let topLevelMenus: RefMenu[];
  if(menus.length === 0) {
    topLevelMenus = [];
  } else if(hasMenuNav) {
    topLevelMenus = menus;
  } else {
    topLevelMenus = menus[0]!.groups.map(group => {
      return {
        ...group,
        groups: []
      } as RefMenu;
    });
  }
  const hasSubGroups = hasMenuNav && hasSubMenuNav;
  return (
    <div className="paddedContentWrapper">
      <div className={classnames('paddedContent', { withoutHero: !hasHero })}>
        <nav role="tablist" className={classnames('topMenuNav', {
          condensed: menuConfig?.template == MenuTemplate.Condensed,
          showBorders
        })} ref={navRef} data-testid="menu-nav">
          {topLevelMenus.length > 0 &&
              <div className="topMenuNavMenuSelection" data-testid="menu-nav-menu-selection">
                <div className="navControl hidden-md-up">
                  <div onClick={() => setShowMobileMenu(!showMobileMenu)} aria-label="Open full menu" role="button">
                    <NavControl color={primaryColor} />
                  </div>
                  <MobileMenuModal menus={topLevelMenus} hasMenuGroups={hasSubGroups} isOpen={showMobileMenu} onClose={() => setShowMobileMenu(false)} />
                </div>
                <div className="topMenuNavWrapper">
                  <button type="button" aria-label="Scroll left" onClick={scroll(ScrollDirection.Backwards)} className={classnames('arrow leftArrow', { arrowHidden: !showLeftArrow })}>
                    <LeftArrow color={primaryColor} />
                  </button>
                  <div className="sections" ref={scrollContainerRef} onScroll={scrollEvent}>
                    {topLevelMenus.map((menuItem, index) =>
                      <MenuNavItem
                        key={menuItem.name}
                        menuItem={menuItem}
                        selected={hasMenuNav ? selectedMenuIndex === index : selectedGroupGuid === menuItem.guid}
                        setSelected={() => {
                          hasMenuNav ? setSelectedMenuIndex(index) : setSelectedGroupGuid(menuItem.guid);
                        }}
                        scrollContainerRef={scrollContainerRef}
                        hasDropdown={hasSubGroups} />)}
                  </div>
                  <button type="button" aria-label="Scroll right" onClick={scroll(ScrollDirection.Forwards)} className={classnames('arrow rightArrow', { arrowHidden: !showRightArrow })}>
                    <RightArrow color={primaryColor} />
                  </button>
                </div>
              </div>}
          {canUseSearch &&
              <div className="topMenuNavSearch">
                <SearchInput />
              </div>}
        </nav>
      </div>
    </div>
  );
};
export const MenuNavItem = (
  {
    menuItem,
    selected,
    setSelected,
    scrollContainerRef,
    hasDropdown
  }: {
    menuItem: RefMenu,
    selected: boolean,
    setSelected: () => void,
    scrollContainerRef: RefObject<HTMLDivElement>,
    hasDropdown: boolean,
  }
) => {
  const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const {
    styles,
    attributes
  } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom-start',
      modifiers: [{
        name: 'offset',
        options: { offset: [30, 15] }/* [skidding, distance], https://popper.js.org/docs/v2/modifiers/offset/ */
      }]
    }
  );
  const { isEditor } = useEditor();
  const { restaurant: { meta } } = useRestaurant();
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const isFullyInView = useCallback(() => {
    if(!referenceElement || !scrollContainerRef.current) {
      return;
    }
    const refRect = referenceElement.getBoundingClientRect();
    const containerRect = scrollContainerRef.current.getBoundingClientRect();
    return refRect.left >= containerRect.left && refRect.right <= containerRect.right;
  }, [referenceElement, scrollContainerRef]);

  useEffect(() => {
    const detectOutsideAction = (event: MouseEvent) => {
      const target = event.target as Node | null;
      if(dropdownIsOpen && !popperElement?.contains(target) && !referenceElement?.contains(target)) {
        setDropdownIsOpen(false);
      }
    };

    const detectSelectionFromVerticalScroll = throttle(() => {
      const menuNavInView = (scrollContainerRef.current?.getBoundingClientRect().bottom ?? 0) > 0;
      if(selected && referenceElement && menuNavInView && !isFullyInView()) {
        referenceElement.scrollIntoView({ block: 'nearest' });
      }
    }, 500);

    if(!isEditor) {
      document.addEventListener('click', detectOutsideAction);
      document.addEventListener('scroll', detectOutsideAction);
      document.addEventListener('scroll', detectSelectionFromVerticalScroll);
      scrollContainerRef.current?.addEventListener('scroll', detectOutsideAction);
    }

    return () => {
      document.removeEventListener('click', detectOutsideAction);
      document.removeEventListener('scroll', detectOutsideAction);
      document.removeEventListener('scroll', detectSelectionFromVerticalScroll);
    };
  }, [dropdownIsOpen, popperElement, scrollContainerRef, referenceElement, selected, isEditor, isFullyInView]);

  function handleMenuItemClick(e: React.KeyboardEvent | React.MouseEvent, item: RefMenu) {
    if(e.type === 'keydown') {
      const keyEvent = e as React.KeyboardEvent;
      if(keyEvent.code !== 'Space' && keyEvent.code !== 'Enter') {
        return; // only continue if the spacebar or enter key is pressed
      }
      e.stopPropagation();
      e.preventDefault();
    }
    setSelected();
    if(hasDropdown) {
      if(!isFullyInView()) {
        referenceElement?.scrollIntoView({ block: 'nearest' });
        // Set a timeout so the event listener that automatically closes the dropdown on scroll
        // doesn't fire before we can scroll the menu item into view and open the dropdown
        setTimeout(() => setDropdownIsOpen(!dropdownIsOpen), 50);
      } else {
        setDropdownIsOpen(!dropdownIsOpen);
      }
    } else {
      scrollToRef(item.ref, isEditor);
    }
  }

  useEffect(() => {
    if(dropdownIsOpen) {
      const options = popperElement?.querySelectorAll('[role="option"]');
      if(options && options.length > 0) {
        const elementToFocus = options.item(0) as HTMLElement;
        // We have to wait for all of the scroll presses to process before moving focus or else
        // the dropdown will close automatically
        setTimeout(() => elementToFocus.focus(), 50);
      }
    }
  }, [dropdownIsOpen, popperElement]);

  const handleTabOnDropdown = useCallback((e: React.KeyboardEvent) => {
    const options = popperElement?.querySelectorAll('[role="option"]');
    const target = e.target as HTMLElement;
    if(options && target.id === options[options.length - 1]?.id) {
      setDropdownIsOpen(false);
      e.stopPropagation();
      e.preventDefault();
      setTimeout(() => dropdownRef.current?.focus(), 50);
    }
  }, [dropdownRef, popperElement]);

  function handleSubMenuItemKeyDown(e: React.KeyboardEvent, item: RefMenuGroup) {
    if(e.code === 'Tab') {
      handleTabOnDropdown(e);
    }
    if(e.code !== 'Escape' && e.code !== 'Space' && e.code !== 'Enter') {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    setDropdownIsOpen(false);
    if(e.code === 'Escape') {
      dropdownRef.current?.focus();
    } else {
      scrollToRef(item.ref, isEditor);
    }
  }

  return (
    <div key={menuItem.name} className="menuItem" ref={setReferenceElement}>
      <div className="menuItemTarget"
        onClick={(e: React.MouseEvent) => handleMenuItemClick(e, menuItem)}
        onKeyDown={(e: React.KeyboardEvent) => handleMenuItemClick(e, menuItem)}
        role={hasDropdown ? 'listbox' : 'tab'}
        data-testid={`menu-item-target-${menuItem.name}`}
        ref={dropdownRef}
        tabIndex={0}
        aria-haspopup={hasDropdown}
        aria-expanded={dropdownIsOpen}
        aria-controls="menu-item-dropdown-content">
        <a
          id={`${normalizeHtmlId(menuItem.name)}-tab`}
          className={`menuLink ${selected ? 'selected' : ''}`}
          aria-selected={selected}
          data-testid={`menu-tab-${menuItem.name}`}>
          {menuItem.name}
        </a>
        {hasDropdown &&
            <div className="dropdownTriangle" data-testid="dropdown-triangle">
              <DropdownTriangle color={meta.primaryColor} />
            </div>}
      </div>
      {hasDropdown && dropdownIsOpen &&
        ReactDOM.createPortal(
          <div className="dropdown" data-testid="menu-item-dropdown" id="menu-item-dropdown-content" ref={setPopperElement} style={styles.popper} {...attributes.popper} role="tablist">
            {menuItem.groups.map(item =>
              <button
                key={item.id}
                id={item.id}
                className="subMenuItem"
                onClick={() => {
                  setDropdownIsOpen(false);
                  scrollToRef(item.ref, isEditor);
                }}
                onKeyDown={e => handleSubMenuItemKeyDown(e, item)}
                tabIndex={1}
                role="option">
                {`${item.name} (${item.items.length})`}
              </button>)}
          </div>, document.body
        )}
    </div>
  );
};

function MobileMenuModal(
  {
    menus,
    hasMenuGroups,
    isOpen,
    onClose
  }: {
    menus: RefMenu[],
    hasMenuGroups: boolean,
    isOpen: boolean,
    onClose: () => void
  }
) {
  const { isEditor } = useEditor();

  function handleClickItem(ref: RefObject<HTMLDivElement>) {
    onClose();
    scrollToRef(ref, isEditor);
  }

  // Scrolling on clicking a menu item was breaking due to conflicts between animation of this motion.div
  // and the wrapping modal's animation.  Pass animateClose={false} to the modal to remove the conflict.
  return (
    <Modal onClose={onClose} isOpen={isOpen} animateClose={false}>
      <ModalOverlay fadeIn fadeOut />
      <motion.div
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        transition={{
          duration: 0.2,
          ease: 'easeIn'
        }}
        className="menuContent"
        data-testid="mobile-menu-content">
        <div className="header"><span>Menu</span> <ModalCloseButton className="closeButton" /></div>
        {menus.map(menu =>
          <div className="section" key={menu.guid}>
            <span
              className="item groupName"
              onClick={() => handleClickItem(menu.ref)}>
              {menu.name}
            </span>
            {hasMenuGroups &&
                <>
                  <div className="separator" />
                  {menu.groups.map(group =>
                    <span
                      className="item"
                      key={group.id}
                      onClick={() => handleClickItem(group.ref)}>
                      {`${group.name} (${group.items.length})`}
                    </span>)}
                </>}
          </div>)}
      </motion.div>
    </Modal>
  );
}

export default MenuNav;
