// Importing hot before everything else. See step 3
// in the documentation for react-hot-loader
// https://github.com/gaearon/react-hot-loader/tree/07d1f4e080f18dd83273be5bd02863ce1caf892e#getting-started
// eslint-disable-next-line import/order
import { hot } from 'react-hot-loader/root';

import React, { PropsWithChildren } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';

import { NormalizedCacheObject } from 'apollo-cache-inmemory';

import { createClient } from 'src/apollo/createClient';
import { DebugContextProvider } from 'src/shared/components/common/debug/DebugContext';
import { useGiaAuth } from 'src/shared/js/hooks/useGiaAuth';
import { AuthProvider } from 'src/vendor/do-secundo-guest-authentication';

import AlertModalProvider from 'shared/components/common/alert_modal/AlertModal';
import { OOClientProvider } from 'shared/components/common/oo_client_provider/OOClientProvider';

import App from 'public/components/app/App';
import Routes from 'public/js/routes';

import config from 'config';

declare global {
  interface Window {
    __SSR_STATUS__: string | undefined | null;
    __SSR_ERR_MSG__: object | undefined | null;
    __OO_STATE__: NormalizedCacheObject | undefined;
    generatedImages: {[k: string]: string}
  }
}

const AuthProviderWrapper = ({ children, gatewayOrigin }: PropsWithChildren<{gatewayOrigin: string}>) => {
  const giaAuth = useGiaAuth();
  return giaAuth
    ? <AuthProvider gatewayOrigin={gatewayOrigin}>{children}</AuthProvider>
    : <>{children}</>;
};

const ClientApp = () => {
  const giaAuth = useGiaAuth();
  return (
    <CookiesProvider>
      <AuthProviderWrapper gatewayOrigin={config.resources.apiHost}>
        <BrowserRouter>
          <DebugContextProvider>
            <OOClientProvider client={host => createClient(host, window.__OO_STATE__, true, false, undefined, true, giaAuth, config.resources.clientQueryTimeoutMs, true)}>
              <AlertModalProvider>
                <App><Routes /></App>
              </AlertModalProvider>
            </OOClientProvider>
          </DebugContextProvider>
        </BrowserRouter>
      </AuthProviderWrapper>
    </CookiesProvider>
  );
};

export default hot(ClientApp);
